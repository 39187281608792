  <div *ngIf="!loading" class="flex flex-wrap min-w-[150] gap-4 mx-4 drop-shadow-sm rounded-xl items-center p-4"
     [ngClass]="{
       'bg-red-500 dark:bg-dark-red-600': status === 'error',
       'bg-yellow-500 dark:bg-dark-yellow-600': status === 'warning',
       'bg-pigment-green-200 dark:bg-dark-green-600': status === 'info' || !status
     }">
  <div *ngIf="mode === 'mobile'" class="flex rounded-full w-16 h-16 items-center justify-center"
       [ngClass]="{
         'bg-red-700 dark:bg-dark-red-800': status === 'error',
         'bg-yellow-700 dark:bg-dark-yellow-800': status === 'warning',
         'bg-pigment-green-400 dark:bg-dark-green-800': status === 'info' || !status
       }">
    <i class="text-gray-100 dark:text-gray-300 text-2xl" [class]="getIconClass()"></i>
  </div>
  <div class="flex flex-col gap-1 flex-grow">
    <p class="text-lg text-black dark:text-white-600">{{ _packageInfo?.name ?? 'Brak aktywnego pakietu' }}</p>
    <div class="relative w-full">
      <div *ngIf="_packageInfo" class="bg-gray-200 rounded-full h-5 overflow-hidden">
        <div
          [style.width]="calculateProgressPercentage() + '%'"
          class="h-5 rounded-full transition-all duration-300"
          [ngClass]="{
            'bg-pigment-green-500': calculateProgressPercentage() >= 10,
            'bg-red-800': calculateProgressPercentage() < 10,
            'dark:bg-dark-red-900': status === 'error' && calculateProgressPercentage() < 10,
            'dark:bg-dark-yellow-900': status === 'warning' && calculateProgressPercentage() < 10
          }"
        ></div>
      </div>
      <div *ngIf="_packageInfo !== null; else nopackage" class="absolute right-0 top-0 h-5 flex items-center pr-2">
        <span class="text-xs font-semibold"
              [ngClass]="{
                'text-white': calculateProgressPercentage() >= 95,
                'text-gray-800': calculateProgressPercentage() < 95,
                'dark:text-dark-gray-300': status === 'info' || !status
              }"
              *ngIf="_packageInfo?.state !== null; else infinity">
          {{ getProgress() }}
        </span>
        <ng-template #infinity>
          <span class="text-white text-md font-semibold">Bez limitu ∞</span>
        </ng-template>
      </div>
      <ng-template #nopackage>
        <p-button pRipple styleClass="w-full !bg-red-600 hover:bg-red-400 !text-gray-900" [outlined]="true" label="Przejdź do zakupu pakietu" (onClick)="router.navigate(['/dashboard/store/plans'])"></p-button>
      </ng-template>
    </div>
    <div *ngIf="_packageInfo" class="flex gap-2 items-center">
      <i class="text-gray-700 dark:text-white-600 dark:text-dark-gray-300 pi pi-calendar"></i>
      <p class="text-sm dark:text-white-600">Ważny do: <b>{{ _packageInfo?.endDate | date }}</b></p>
    </div>
  </div>
</div>

<p-skeleton *ngIf="loading" styleClass="mx-4" height="8rem" borderRadius="8px" width="295px"></p-skeleton>
