export class ChangeEmployeePasswordRequest{
  private username: string
  private newPassword: string
  private newPasswordConfirmation: string

  constructor(username: string, newPassword: string, newPasswordConfirmation: string){
    this.username = username;
    this.newPassword = newPassword;
    this.newPasswordConfirmation = newPasswordConfirmation;
  }
}
