import { PurchaseInvoiceType } from "./purchase-invoice-type.model";
import { PurchaseInvoice } from "./purchase-invoice.model";

export class CompanyInvoice implements PurchaseInvoice{
  type: PurchaseInvoiceType = PurchaseInvoiceType.COMPANY_INVOICE;
  firstName: string
  lastName: string
  companyName: string
  nipNumber: string
  address: string
  postCode: string
  city: string
}
