import { Injectable } from '@angular/core';
import { EvidenceCredentials } from './evidence-credentials.model';
import { CompanyContext } from 'src/app/common/ui/company-context-select/models/company-context.model';
import { CompanyContextState } from 'src/app/common/ui/company-context-select/company-context.reducer';

const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'
const EVIDENCE_CREDENTIALS = 'evidence_credentials'
const COMPANY_CONTEXT = 'company_context'

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  saveToken(token): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  saveRefreshToken(refreshToken): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  removeCompanyContext() {
    localStorage.removeItem(COMPANY_CONTEXT);
  }

  saveEvidenceCredentials(credentials: EvidenceCredentials): void {
    localStorage.setItem(EVIDENCE_CREDENTIALS, JSON.stringify(credentials))
  }

  getEvidenceCredentials(): EvidenceCredentials {
    return JSON.parse(localStorage.getItem(EVIDENCE_CREDENTIALS));
  }

  getCompanyContext(): CompanyContext {
    const state = (JSON.parse(localStorage.getItem('companyContext')))?.companyContext as CompanyContextState;

    const companyContext = new CompanyContext(
      "",
      state?.selectedCompany?.credentialsId,
      state?.selectedCompanyUnit?.bdoId,
      state?.selectedRole?.value,
      4
    );

    return companyContext;
  }

  saveCompanyContext(companyContext: CompanyContext): void {
    localStorage.setItem(COMPANY_CONTEXT, JSON.stringify(companyContext))
  }
}
