<div class="flex flex-col sm:flex-row sm:justify-between items-center w-full">
  <div class="m-4">
    <h1 class="font-semibold text-xl w-full text-center sm:text-left dark:text-white"> {{ title }} </h1>
    <span *ngIf="subtitle" class="text-md w-full text-center sm:text-left text-gray-500 dark:text-gray-300"> {{ subtitle }} </span>
  </div>
  <div class="flex gap-2 justify-center sm:justify-end p-4">
    <ng-container *ngTemplateOutlet="actionButtonsTemplate"></ng-container>
  </div>
</div>

<ng-template data-type="actionButtons">

</ng-template>
