<div>
  <h3 class="text-xl font-semibold mb-4">Edycja profilu</h3>
  <form [formGroup]="form" class="flex flex-col gap-4 w-full">
    <div class="flex flex-col gap-2">
      <label for="firstName">Imię</label>
      <input pInputText id="firstName" formControlName="firstName" required/>
      <small class="text-red-500" *ngIf="!form.controls['firstName'].valid && form.controls['firstName'].touched">{{ getError('firstName') }}</small>
    </div>

    <div class="flex flex-col gap-2">
      <label for="lastName">Nazwisko</label>
      <input pInputText id="lastName" formControlName="lastName" required/>
      <small class="text-red-500" *ngIf="!form.controls['lastName'].valid && form.controls['lastName'].touched">{{ getError('lastName') }}</small>
    </div>

    <div class="flex flex-col gap-2">
      <label for="email">Adres e-mail</label>
      <input pInputText id="email" formControlName="email" disabled/>
    </div>

    <div class="flex flex-col gap-2">
      <label for="companyName">Nazwa firmy</label>
      <input pInputText id="companyName" formControlName="companyName" required/>
      <small class="text-red-500" *ngIf="!form.controls['companyName'].valid && form.controls['companyName'].touched">{{ getError('companyName') }}</small>
    </div>

    <div class="flex flex-col gap-2">
      <p-checkbox binary="true" formControlName="marketingAgreement" label="Chcę otrzymywać newsletter"></p-checkbox>
    </div>

    <p-message *ngIf="editProfileError" severity="error" text="{{ editProfileError }}"></p-message>
    <p-button (onClick)="submitProfile()" [disabled]="!form.valid" [loading]="saving">Zapisz</p-button>
  </form>
</div>
