<div class="login-container">
  <img src="../../assets/logo.png" alt="BDO mobile" />
  <div class="login-column">
    <div class="bg-white p-6 shadow rounded">
      <div class="login-logo">
        <h2 class="text-2xl font-bold">Panel użytkownika</h2>
      </div>
      <div class="mt-4">
        <button pButton type="button" label="Zaloguj" icon="pi pi-sign-in" class="w-full" routerLink="/dashboard" style="width: 100%">Zaloguj</button>
      </div>
      <h4 class="text-lg mt-4">Nie masz konta?</h4>
      <div class="mt-4">
        <a pButton class="w-full" routerLink="/register" style="width: 100%">Zarejestruj się</a>
      </div>
    </div>
    <span class="app-version">BDO mobile v.{{ appVersion }}</span>
  </div>
</div>
