import { StoreProduct } from "../../bdoservice/store/models/store-product.model";

export class AddCustomPlanRequest {

  static fromStoreProduct(product: StoreProduct) {
    return new AddCustomPlanRequest(
      product.title,
      StoreProduct.getDurationDays(product),
      product.cards_number
    )
  }

  constructor(
    readonly name: string,
    readonly days: number,
    readonly operationCount?: number,
  ){}
}
