<div class="dark:text-white">
  <app-header-title title="Sklep" />
  <div *ngIf="productsListError" class="flex flex-col md:flex-row items-center justify-between bg-red-600 text-white border-red-700 border rounded-xl shadow-md mx-2 my-2 p-4">
    <p>{{ productsListError }}</p>
    <p-button *ngIf="!productsListError" label="Odśwież" icon="pi pi-refresh" (onClick)="loadPage()" [disabled]="productsLoading" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 md:mt-0"></p-button>
  </div>
  <div *ngIf="!productsListError" class="flex flex-col gap-4 mt-4 p-4 mx-2">
    <div class="flex flex-col gap-2">
      <div class="flex flex-col gap-4 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <h1 class="font-semibold text-2xl mb-2">Kredyty</h1>
        <p>
          Skorzystaj również z możliwości zakupu kredytów BDO mobile, które umożliwią Ci jeszcze wygodniejsze zarządzanie kartami, ewidencją oraz raportami w ramach aplikacji BDO mobile. Te kredyty to elastyczny sposób na dostosowanie usług do Twoich indywidualnych potrzeb, pozwalając na efektywne i skuteczne zarządzanie zasobami i danymi.
        </p>
        <div class="flex flex-col md:flex-row gap-4">
          <app-shop-package-item class="w-full md:w-1/2" [canAdminAdd]="true" [product]="actualPackageProduct" [companyProfile]="companyProfile" [disableBuy]="disableBuy"></app-shop-package-item>
          <div class="">
            <h3 class="font-semibold text-xl mb-2">Parametry pakietu</h3>
            <p>Skorzystaj z naszego konfiguratora, aby zakupić pakiet odpowiedni dla Twojej firmy.</p>
            <div class="flex flex-col gap-2 mt-4">
              <p class="font-semibold">Okres trwania:</p>
              <p-selectButton class="hover:cursor-pointer" [options]="durations" [(ngModel)]="selectedDuration" [multiple]="false" (onChange)="onChangeDuration($event)"></p-selectButton>
            </div>
            <div class="flex flex-col gap-2 mt-4">
              <p class="font-semibold">Liczba kart:</p>
              <p-selectButton class="hover:cursor-pointer" [options]="cardsCount" [(ngModel)]="selectedCardsCount" [multiple]="false" (onChange)="onChangeCardsCount($event)" [disabled]="customCountAdminEnabled"></p-selectButton>
              <div class="flex gap-2 mt-4" *ngIf="isAdmin()">
                <p-inputSwitch [(ngModel)]="customCountAdminEnabled" inputId="customCountAdmin" (ngModelChange)="onCustomCountAdminEnabledChange()"></p-inputSwitch>
                <label for="customCountAdmin">Własna wartość <fa-icon [icon]="adminIcon"></fa-icon></label>
              </div>
              <div *ngIf="isAdmin() && customCountAdminEnabled">
                <p-inputNumber [(ngModel)]="customCount" [min]="1" [max]="1000" [showButtons]="true" [buttonLayout]="'horizontal'" (ngModelChange)="onCustomCountChange($event)"></p-inputNumber>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
        <h1 class="font-semibold text-2xl mb-2">Nasze usługi</h1>
        <p>
          Odkryj pełen zakres możliwości z naszymi subskrypcjami na ekowitryna.pl! Z subskrypcją uzyskasz ekskluzywny dostęp do najnowszych aktualizacji, rozszerzeń funkcjonalności oraz narzędzi, które pomogą Ci w ochronie środowiska i przestrzeganiu przepisów prawnych. Ciesz się rocznym dostępem do naszej platformy, korzystaj z indywidualnych konsultacji z ekspertami i bądź zawsze na bieżąco z najnowszymi trendami i rozwiązaniami. Wybierz subskrypcję, która najlepiej odpowiada Twoim potrzebom i zacznij korzystać z pełni możliwości ekowitryna.pl.
        </p>
        <p-carousel [value]="filteredSubscriptions" [numVisible]="3" [numScroll]="3" [circular]="true" class="mt-4">
          <ng-template let-product pTemplate="item">
            <app-shop-package-item [product]="product" [companyProfile]="companyProfile"></app-shop-package-item>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>
</div>
