<div class="bg-white dark:bg-gray-800 shadow-md border border-gray-200 dark:border-gray-700 rounded-xl m-2 p-4 text-center">
  <h1 class="text-lg font-semibold mb-2 dark:text-white">{{ product?.title }}</h1>

  <!-- Image container with promotion ribbon -->
  <div class="relative">
    <img *ngIf="product" class="w-32 h-32 object-cover rounded-md mx-auto" src="{{ product?.image_link }}" alt="{{ product?.title }}" />
    <div *ngIf="product?.sale_price" class="absolute top-1/2 left-1/2 bg-red-500 text-white py-1 px-3 transform -translate-x-1/2 -translate-y-1/2">
      Promocja
    </div>
    <ng-container *ngIf="!product">
      <div class="animate-pulse bg-gray-200 w-32 h-32 object-cover rounded-md mx-auto"></div>
    </ng-container>
    <!-- Conditional rendering of promotion ribbon -->

  </div>

 <div *ngIf="product">
   <!-- price and sale price if exists -->
   <div>
    <p class="text-xl font-bold dark:text-white" [ngClass]="{'text-red-500': product?.sale_price}">{{ getPrice() }} zł</p>
  </div>

  <p class="text-sm text-gray-500 dark:text-gray-300 mt-1 mb-4">* podane ceny są kwotami netto.</p>
 </div>
 <ng-container *ngIf="!product">
  <p class="mt-2 font-semibold py-2 dark:text-white">Proszę wybrać opcje dla pakietu kart.</p>
 </ng-container>
  <p-button label="Kup teraz" icon="pi pi-shopping-cart" class="mt-4" (onClick)="onBuyClick()" [disabled]="disableBuy || !product" ></p-button>
  <p-button *ngIf="isAdmin() && canAdminAdd" label="Dodaj do konta" class="mt-4 ml-2" (onClick)="adminAddClick()" severity="help" [loading]="addCustomPlanLoading">
    <ng-template pTemplate="iconTemplate">
      <div class="flex gap-2">
        <fa-icon [icon]="adminIcon"></fa-icon>
        <span>Dodaj</span>
      </div>
    </ng-template>
  </p-button>
</div>
