import { PurchaseInvoiceType } from "./purchase-invoice-type.model";
import { PurchaseInvoice } from "./purchase-invoice.model";

export class PersonInvoice implements PurchaseInvoice{
  type: PurchaseInvoiceType = PurchaseInvoiceType.PERSON_INVOICE;
  firstName: string
  lastName: string
  nipNumber: string
  address: string
  postCode: string
  city: string
}
