<form [formGroup]="form" class="flex flex-col gap-8 w-full">
  <div class="flex gap-4 mb-2">
    <p-radioButton
      name="type"
      formControlName="type"
      *ngFor="let item of formTypes"
      [value]="item.value"
      [label]="item.name">
    </p-radioButton>
  </div>

  <div>
    <span class="p-float-label">
     <input pInputText  id="firstName" formControlName="firstName" class="!w-full" required/>
      <label for="firstName">Imię<span *ngIf="isRequired('firstName')" title="required" class="text-red-600">*</span></label>
    </span>
  </div>

  <div>
    <span class="p-float-label">
      <input pInputText id="lastName" formControlName="lastName" class="!w-full" required/>
      <label for="lastName">Nazwisko<span *ngIf="isRequired('lastName')" title="required" class="text-red-600">*</span></label>
    </span>
  </div>

  <div *ngIf="selectedType === InvoiceType.COMPANY">
    <span class="p-float-label">
      <input pInputText id="companyName" formControlName="companyName" class="!w-full" required/>
      <label for="companyName">Nazwa firmy<span *ngIf="isRequired('companyName')" title="required" class="text-red-600">*</span></label>
    </span>
  </div>

  <div *ngIf="selectedType === InvoiceType.COMPANY">
    <span class="p-float-label">
      <input pInputText  id="nipNumber" formControlName="nipNumber" class="!w-full" [required]="selectedType === InvoiceType.COMPANY"/>
      <label for="nipNumber">NIP<span *ngIf="isRequired('nipNumber')" title="required" class="text-red-600">*</span></label>
    </span>
    <p-message *ngIf="!form.controls.nipNumber.valid && form.controls.nipNumber.touched" severity="error" text="{{ getNipNumberError() }}"></p-message>
  </div>

  <div>
    <span class="p-float-label">
      <input pInputText  id="address" formControlName="address" class="!w-full" required/>
      <label for="address">Adres<span *ngIf="isRequired('address')" title="required" class="text-red-600">*</span></label>
    </span>
  </div>

  <div>
    <span class="p-float-label">
      <input pInputText  id="postCode" formControlName="postCode" class="!w-full" required/>
      <label for="postCode">Kod pocztowy<span *ngIf="isRequired('postCode')" title="required" class="text-red-600">*</span></label>
    </span>
    <p-message *ngIf="!form.controls.postCode.valid && form.controls.postCode.touched" severity="error" text="{{ getPostCodeError() }}"></p-message>
  </div>

  <div>
    <span class="p-float-label">
     <input pInputText  id="city" formControlName="city" class="!w-full" required/>
      <label for="city">Miasto<span *ngIf="isRequired('city')" title="required" class="text-red-600">*</span></label>
    </span>
  </div>

  <p-message *ngIf="saveCompanyInvoiceError" severity="error" text="{{ saveCompanyInvoiceError }}"></p-message>
  <p-button label="Przejdź do płatności" (onClick)="saveCompanyInvoice()" [loading]="saving" [disabled]="!form.valid"></p-button>
</form>
