<div class="flex flex-col gap-4">
  <h3 class="font-semibold text-xl">Zmiana hasła</h3>
  <form [formGroup]="changePasswordForm" class="flex flex-col gap-2">
    <div class="flex flex-col gap-2">
      <label for="password">Nowe hasło</label>
      <p-password id="password" formControlName="password" [feedback]="false" placeholder="Nowe hasło" toggleMask></p-password>
      <small class="text-red-500" *ngIf="!changePasswordForm.controls['password'].valid && changePasswordForm.controls['password'].touched">{{ getPasswordError() }}</small>
    </div>
    <div class="flex flex-col gap-2">
      <label for="password2">Powtórz hasło</label>
      <p-password id="password2" formControlName="password2" [feedback]="false" placeholder="Powtórz hasło" toggleMask></p-password>
      <small class="text-red-500" *ngIf="!changePasswordForm.controls['password2'].valid && changePasswordForm.controls['password2'].touched">{{ getPassword2Error() }}</small>
    </div>
    <p-button label="Zapisz" (onClick)="changePassword()" [disabled]="!changePasswordForm.valid" [loading]="saving"></p-button>
  </form>
</div>
