import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CompanyContextState } from './company-context.reducer';

export const selectCompanyContext = createFeatureSelector<CompanyContextState>('companyContext');

export const selectCompanies = createSelector(
  selectCompanyContext,
  (state) => state.companies
);

export const selectCompanyUnits = createSelector(
  selectCompanyContext,
  (state) => state.companyUnits
);

export const selectSelectedCompany = createSelector(
  selectCompanyContext,
  (state) => state.selectedCompany
);

export const selectSelectedCompanyUnit = createSelector(
  selectCompanyContext,
  (state) => state.selectedCompanyUnit
);

export const selectShowHidden = createSelector(
  selectCompanyContext,
  (state) => state.showHidden
);

export const selectLoadingCompanies = createSelector(
  selectCompanyContext,
  (state) => state.loadingCompanies
);

export const selectLoadingCompanyUnits = createSelector(
  selectCompanyContext,
  (state) => state.loadingCompanyUnits
);

export const selectError = createSelector(
  selectCompanyContext,
  (state) => state.error
);

export const selectSelectedRole = createSelector(
  selectCompanyContext,
  (state) => state.selectedRole
);
