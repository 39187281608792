import { BdoCredentials } from "../../companies/models/bdo-credentials.model";

export class Employee{
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  credentials: BdoCredentials[] = [];
  mainAccountCreated: boolean;
}
