import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InvoiceType, SavedInvoiceItem } from 'src/app/dashboard/profile/edit-company-invoice-card/edit-company-invoice-card.component';
import { StoreProduct } from 'src/app/services/bdoservice/store/models/store-product.model';
import { CompanyProfile } from 'src/app/services/bdoservice/users/models/company-profile.model';

@Component({
  selector: 'app-invoice-form-dialog',
  templateUrl: './invoice-form-dialog.component.html',

})
export class InvoiceFormDialogComponent {

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<InvoiceFormDialogData>,
  ) {}

  isWaitingToRedirect = false;

  onSaved(item: SavedInvoiceItem){
    this.isWaitingToRedirect = true;
    const form = document.createElement('form');
    form.action = 'https://ekowitryna.pl/zamowienie/';
    form.method = 'POST';

    this.createHiddenInput(form, 'add-to-cart', this.config.data.product.id.toString());

    if(item.type === InvoiceType.COMPANY){
      this.createInput(form, 'billing_first_name', this.config.data.companyProfile.firstName);
      this.createInput(form, 'billing_last_name', this.config.data.companyProfile.lastName);
      if ('companyName' in item.invoice) {
        this.createInput(form, 'billing_company', item.invoice.companyName);
      }
      this.createInput(form, 'billing_tax_no', item.invoice.nipNumber);
    }

    if(item.type === InvoiceType.PERSON){
      this.createInput(form, 'billing_first_name', item.invoice.firstName);
      this.createInput(form, 'billing_last_name', item.invoice.lastName);
    }

    this.createInput(form, 'billing_address_1', item.invoice.address);
    this.createInput(form, 'billing_city', item.invoice.city);
    this.createInput(form, 'billing_postcode', item.invoice.postCode);
    this.createInput(form, 'billing_email', this.config.data.companyProfile.email);


    // Dodanie formularza do dokumentu i wysłanie
    document.body.appendChild(form);
    form.submit();
  }


  createInput(form: HTMLFormElement, name: string, value: string) {
    const input = document.createElement('input');
    input.type = 'text';
    input.name = name;
    input.value = value;
    form.appendChild(input);
  }

  createHiddenInput(form: HTMLFormElement, name: string, value: string) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    form.appendChild(input);
  }
}

export class InvoiceFormDialogData{
  constructor(
    readonly companyProfile: CompanyProfile,
    readonly product: StoreProduct,
  ){}
}
