import { Component } from '@angular/core';

@Component({
  selector: 'app-create-company-instructions',
  templateUrl: './create-company-instructions.component.html',

})
export class CreateCompanyInstructionsComponent {

  openContactMail(){
    //Generate mailto with title "Wsparcie z integracją BDO mobile" to kontakt@ekowitryna.pl
    window.open("mailto:kontakt@ekowitryna.pl?subject=Wsparcie%20z%20integracj%C4%85%20podmiotu%20w%20BDO%20mobile", "_blank");
  }


  openTutorialVideo(){
    window.open("https://youtu.be/i1zZkm7tOWg", "_blank", "noopener");
  }

  openWikiPage(){
    window.open("https://wiki.bdomobile.com/bdo-mobile/pierwsze-kroki", "_blank", "noopener");
  }

}
