import { FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function ConfirmedValidator(controlName: string, matchingControlName: string, isEdit: boolean): ValidatorFn {
  return (formGroup: UntypedFormGroup | FormGroup): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (isEdit && matchingControl.value === '' && control.value === '') {
      matchingControl.setErrors(null);
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
      return { confirmedValidator: true }; // return error object when validation fails
    } else {
      matchingControl.setErrors(null);
    }

    return null;
  };
}
