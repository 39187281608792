/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinToString'
})
export class JoinToStringPipe implements PipeTransform {

  transform(value: string[], separator: string, ...args: unknown[]): unknown {
    if(separator){
      return value.join(separator);
    }
    return value.join(', ');
  }
}
