import { KeycloakValidators } from './../../../common/validators/keycloak-validators/keycloak-validators';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ConfirmedValidator } from 'src/app/common/validators/confirmed-password-validator/confirmed-password.validator';
import { AccountService } from 'src/app/services/account.service';

import { AccountValidators } from 'src/app/services/bdoservice/constants';
import { ChangeEmployeePasswordRequest } from 'src/app/services/bdoservice/users/models/change-employee-password-request.model';
import { EditEmployeePasswordRequest } from 'src/app/services/bdoservice/users/models/edit-employee-password-request.model';
import { UsersService, ChangePasswordRequest, AccountType } from 'src/app/services/bdoservice/users/users.service';

@Component({
  selector: 'app-edit-password-card',
  templateUrl: './edit-password-card.component.html',

})
export class EditPasswordCardComponent implements OnInit {

  constructor(
    private usersService: UsersService,
    private messageService: MessageService,
    private accountService: AccountService,
  ) { }


  changePasswordForm: FormGroup;
  changePasswordError = '';
  passwordHide = true;
  saving = false;

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(AccountValidators.MIN_PASSWORD_LENGTH), KeycloakValidators.passwordValidator()]),
      password2: new FormControl('', [Validators.required])
    }, { validators: ConfirmedValidator('password', 'password2', false) });
  }

  getPasswordError(): string {
    const field = this.changePasswordForm.get('password');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('minlength')) {
      return `Pole musi składać się z min. ${AccountValidators.MIN_PASSWORD_LENGTH} znaków.`;
    }

    if (field.hasError('uppercase')) {
      return 'Pole musi zawierać co najmniej jedną wielką literę.';
    }

    if (field.hasError('specialCharacter')) {
      return 'Pole musi zawierać co najmniej jeden znak specjalny.';
    }

    return '';
  }

  getPassword2Error(): string {
    const field = this.changePasswordForm.get('password2');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('confirmedValidator')) {
      return "Hasła nie są identyczne.";
    }

    return '';
  }

  changePassword() {
    this.changePasswordError = '';
    this.saving = true;
    const formValue = this.changePasswordForm.value


    //if is company
    if(this.accountService.getAccountType() === AccountType.COMPANY){
      const request = new ChangePasswordRequest(
        formValue.password,
      );

      this.usersService.changeCompanyPassword(request).then(() => {
        this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Hasło zostało zmienione!' });
      }).catch(err => {
        this.changePasswordError = err.message;
      }).finally(() => {
        this.saving = false;
      });
    } else if (this.accountService.getAccountType() === AccountType.EMPLOYEE){
      const request = new EditEmployeePasswordRequest(
        formValue.password,
        formValue.password2
      );

      this.usersService.editEmployeePassword(request).then(() => {
        this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Hasło zostało zmienione!' });
      }).catch(err => {
        this.changePasswordError = err.message;
      }).finally(() => {
        this.saving = false;
      });
    }

  }
}
