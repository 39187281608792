export class CreateCompanyRequest {
  constructor(
    readonly name: string,
    readonly companyNameFromBdo: boolean,
    readonly nip: string,
    readonly nipEu: string,
    readonly bdoClientId: string,
    readonly bdoClientSecret: string,
  ) {}
}
