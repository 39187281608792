import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IconDefinition, faLifeRing } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',

})
export class HelpComponent {

  helpItems = [
    new HelpItem("Formularz zgłoszeniowy", faLifeRing, "https://mobiletract.atlassian.net/servicedesk/customer/portal/2"),
    new HelpItem("Baza wiedzy", faLifeRing, "https://wiki.bdomobile.com"),
  ]
  constructor(@Inject(DOCUMENT) private document: Document) { }

  goToUrl(url: string): void {
    this.document.location.href = url;
  }

}

export class HelpItem{
  constructor(
    readonly name: string,
    readonly icon: IconDefinition,
    readonly url: string){

  }
}
