/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'maxTextLength'})
export class MaxTextLengthPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    const maxLength = args[0];
    if(value.toString().length > maxLength){
      const newText = value.toString().slice(0, maxLength);
      return newText + "…";
    } else {
      return value.toString();
    }

  }
}
