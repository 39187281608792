import { Role } from "src/app/services/bdoservice/wasterecord/models/search-card.model";

export class CompanyContext{
  static readonly SCHEMA_VERSION = 2;

  constructor(
    public readonly username: string,
    public readonly companyId: string,
    public readonly eupId: string,
    public readonly role: Role,
    public readonly schemaVersion: number = CompanyContext.SCHEMA_VERSION
    ){}
}
