import { Injectable } from "@angular/core";
import { GenericListComponentStore } from "src/app/common/store/generic-list-component-store";
import { CompanyService } from "src/app/services/bdoservice/companies/company.service";
import { BdoCredentials } from "src/app/services/bdoservice/companies/models/bdo-credentials.model";


@Injectable()
export class CredentialsListComponentStore extends GenericListComponentStore<BdoCredentials, CredentialsFetchParams> {

  constructor(
    private readonly companyService: CompanyService,
  ) {
    super((data) => this.companyService.getCompanyCredentials(data.params.companyId, data.page, data.pageSize, data.query, data.sortBy));
  }
}

export interface CredentialsFetchParams {
  companyId: string;
}
