import { ActionReducer, INIT, UPDATE, createReducer, on } from '@ngrx/store';
import {
  loadCompanies,
  loadCompaniesSuccess,
  loadCompaniesFailure,
  loadCompanyUnits,
  loadCompanyUnitsSuccess,
  loadCompanyUnitsFailure,
  changeSelectedCompany,
  changeSelectedCompanyUnit,
  changeShowHidden,
  changeSelectedRole,
} from './company-context.actions';
import { BdoServicePage } from 'src/app/services/bdoservice/common/model/bdo-service-page.model';
import { CompanyUnit } from 'src/app/services/bdoservice/companies/models/company-unit.model';
import { Company } from 'src/app/services/bdoservice/companies/models/company.model';
import { Role } from 'src/app/services/bdoservice/wasterecord/models/search-card.model';
import { RoleDropdownItem } from './company-context-select.component';
import { BdoCompanyItem } from 'src/app/services/bdoservice/companies/models/bdo-company-item';


export interface CompanyContextState {
  companies: BdoCompanyItem[] | null;
  companyUnits: BdoServicePage<CompanyUnit> | null;
  selectedCompany: BdoCompanyItem | null;
  selectedRole: RoleDropdownItem | null;
  selectedCompanyUnit: CompanyUnit | null;
  showHidden: boolean;
  loadingCompanies: boolean;
  loadingCompanyUnits: boolean;
  error: string | null;
}

export const initialState: CompanyContextState = {
  companies: null,
  companyUnits: null,
  selectedCompany: null,
  selectedRole: null,
  selectedCompanyUnit: null,
  showHidden: false,
  loadingCompanies: true,
  loadingCompanyUnits: true,
  error: null
};

export const companyContextReducer = createReducer(
  initialState,
  // Handle loading companies
  on(loadCompanies, (state) => ({
    ...state,
    loadingCompanies: true,
    error: null
  })),
  on(loadCompaniesSuccess, (state, { items }) => ({
    ...state,
    loadingCompanies: false,
    companies: items
  })),
  on(loadCompaniesFailure, (state, { error }) => ({
    ...state,
    loadingCompanies: false,
    error
  })),

  // Handle loading company units
  on(loadCompanyUnits, (state) => ({
    ...state,
    loadingCompanyUnits: true,
    error: null
  })),
  on(loadCompanyUnitsSuccess, (state, { page }) => ({
    ...state,
    loadingCompanyUnits: false,
    companyUnits: page
  })),
  on(loadCompanyUnitsFailure, (state, { error }) => ({
    ...state,
    loadingCompanyUnits: false,
    error
  })),

  // Handle change selected company
  on(changeSelectedCompany, (state, { item }) => ({
    ...state,
    selectedCompany: item,
    companyUnits: null
  })),

  // Handle change selected company unit
  on(changeSelectedCompanyUnit, (state, { companyUnit }) => ({
    ...state,
    selectedCompanyUnit: companyUnit
  })),

  // Handle toggle show hidden company units
  on(changeShowHidden, (state, { showHidden }) => ({
    ...state,
    showHidden
  })),

  on(changeSelectedRole, (state, { role }) => ({
    ...state,
    selectedRole: role
  }))
);

export function localStorageMetaReducer<T>(reducer: ActionReducer<T>): ActionReducer<T> {
  return function (state, action) {
    // On INIT or UPDATE, restore the state from localStorage
    if (action.type === INIT || action.type === UPDATE) {
      const savedState = localStorage.getItem('companyContext');
      if (savedState) {
        try {
          const parsedState = JSON.parse(savedState);
          // Return the restored state, ensuring that the reducer initializes with this state
          return { ...state, ...parsedState };
        } catch {
          // If there's an error parsing the state, remove the corrupted state
          localStorage.removeItem('companyContext');
        }
      }
    }

    // Process the action with the current reducer
    const nextState = reducer(state, action);

    // Save the new state to localStorage
    localStorage.setItem('companyContext', JSON.stringify(nextState));

    return nextState;
  };

}
