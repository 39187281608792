import { Component, OnInit } from '@angular/core';
import packageInfo from '../../../../package.json';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',

})
export class LoginComponent implements OnInit {
  appVersion = '0.0.0';

  ngOnInit(): void {
    this.appVersion = packageInfo.version;
  }
}
