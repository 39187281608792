<p-confirmPopup></p-confirmPopup>

<app-header-title title="Użytkownicy" subtitle="Lista użytkowników">
  <ng-template #actionButtonsTemplate>
    <p-button label="Dodaj użytkownika" icon="pi pi-plus" (onClick)="showCreateDialog()"></p-button>
  </ng-template>
</app-header-title>

<div class="flex bg-white dark:bg-gray-800 rounded-lg shadow-md mx-4 p-2">
  <p-table [value]="users" class="mb-4 z-index-20 w-full"
    [paginator]="true"
    [rows]="15"
    [lazy]="true"
    [loading]="loading"
    styleClass="p-datatable-striped p-datatable-gridlines"
    responsiveLayout="stack"
    [breakpoint]="'960px'"
    >
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">Imię i nazwisko</th>
        <th scope="col"> Nazwa użytkownika</th>
        <th scope="col">Podmiot</th>
        <th scope="col">Akcje</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>{{ user.firstName }} {{ user.lastName }}</td>
        <td>{{ user.username }}</td>
        <!-- Chips -->
        <td>
          <div class="flex gap-2 items-center">
            <p-chip [label]="getFirstCredentials(user)" image="https://ui-avatars.com/api/?size=128&background=random&name={{ getFirstCredentials(user) }}"></p-chip>
            <span *ngIf="user.credentials?.length > 1" class="underline cursor-pointer" [pTooltip]="getCredentialsNames(user.credentials) | joinToString">
              + {{ user.credentials.length - 1 }} więcej
            </span>
          </div>
        </td>
        <td>
          <div class="flex gap-2">
            <p-button label="Edytuj" icon="pi pi-pencil" (onClick)="showEditDialog(user)"></p-button>
            <p-button label="Zmień hasło" icon="pi pi-key" (onClick)="showChangePasswordDialog(user)"></p-button>
            <p-button [label]="getRemoveLabel()" icon="pi pi-trash" severity="danger" (onClick)="removeUser($event, user)"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

