import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BdoServicePage } from '../common/model/bdo-service-page.model';
import { last, lastValueFrom } from 'rxjs';
import { Company } from './models/company.model';
import { BdoCredentials } from './models/bdo-credentials.model';
import { AddBdoCredentialsRequest } from './models/add-bdo-credentials-request.model';
import { AddBdoCredentialsResponse } from './models/add-bdo-credentials-response.model';
import { EditBdoCredentialsRequest } from './models/edit-bdo-credentials-request.model';
import { CompanyUnit } from './models/company-unit.model';
import { CreateCompanyRequest } from './models/create-company-request.model';
import { BdoCompanyItem } from './models/bdo-company-item';
import { CompanyBdoCredentials } from './models/company-bdo-credentials';
import { UpdateCompanyRequest } from './models/update-company-request.model';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

  getCompanies(page: number, pageSize: number, query?: string, sortBy?: string): Promise<BdoServicePage<Company>> {
    const source =  this.http.get<BdoServicePage<Company>>(BASE_URL + 'accounts/company/list',
      { params: { page: page, size: pageSize, query: query, sortBy: sortBy } }
    );

    return lastValueFrom(source);
  }
  
  getCompany(companyId: string): Promise<Company> {
    const source =  this.http.get<Company>(BASE_URL + `accounts/company`, 
      { params: { companyId: companyId } }
    );

    return lastValueFrom(source);
  }

  getBdoCompanies(): Promise<BdoCompanyItem[]> {
    const source =  this.http.get<BdoCompanyItem[]>(BASE_URL + 'accounts/company/list/bdo');

    return lastValueFrom(source);
  }

  getCompanyCredentials(companyId: string, page: number, pageSize: number, query?: string, sortBy?: string): Promise<BdoServicePage<BdoCredentials>> {
    const source = this.http.get<BdoServicePage<BdoCredentials>>(BASE_URL + 'accounts/company/bdo/credentials',
      { params: {companyId: companyId, page: page, size: pageSize, query: query, sortBy: sortBy} }

    );

    return lastValueFrom(source);
  }

  getAllCredentials(): Promise<CompanyBdoCredentials[]> {
    const source = this.http.get<CompanyBdoCredentials[]>(BASE_URL + 'accounts/company/bdo/credentials/all',    );

    return lastValueFrom(source);
  }

  createCompanyCredentials(request: AddBdoCredentialsRequest): Promise<AddBdoCredentialsResponse> {
    const source = this.http.post<AddBdoCredentialsResponse>(BASE_URL + 'accounts/company/bdo/credentials', request);
    return lastValueFrom(source);
  }

  editCompanyCredentials(request: EditBdoCredentialsRequest): Promise<void> {
    const source = this.http.post<void>(BASE_URL + 'accounts/company/bdo/credentials/update', request);
    return lastValueFrom(source);
  }

  deleteCompanyCredentials(credentialId: string): Promise<void> {
    const source = this.http.delete<void>(BASE_URL + `accounts/company/bdo/credentials/${credentialId}`);
    return lastValueFrom(source);
  }

  getCompanyUnits(companyId: string, hideInactive: boolean, page: number, pageSize: number, query?: string): Promise<BdoServicePage<CompanyUnit>> {
    const source = this.http.get<BdoServicePage<CompanyUnit>>(BASE_URL + 'accounts/company/unit/list',
      { params: {companyId: companyId, page: page.toString(), size: pageSize.toString(), query: '', hideInactive: hideInactive} }
    );

    return lastValueFrom(source);
  }

  createCompany(request: CreateCompanyRequest): Promise<any> {
    const source = this.http.post<any>(BASE_URL + 'accounts/company/create', request);
    return lastValueFrom(source);
  }

  deleteCompany(companyId: string): Promise<void> {
    const source = this.http.delete<void>(BASE_URL + `accounts/company/${companyId}`);
    return lastValueFrom(source);
  }
  
  setHeadquarters(companyId: string, unitId: string): Promise<void> {
    const source = this.http.get<void>(BASE_URL + `accounts/company/unit/setHeadquarters`, { params: { companyId: companyId, companyUnitId: unitId } });
    return lastValueFrom(source);
  }

  syncCompany(companyId: string): Promise<void> {
    const source = this.http.get<void>(BASE_URL + `accounts/company/sync`, { params: { companyId: companyId } });
    return lastValueFrom(source);
  }

  updateCompany(request: UpdateCompanyRequest): Promise<any> {
    const source = this.http.post<any>(BASE_URL + `accounts/company/update`, request);
    return lastValueFrom(source);
  }

}
