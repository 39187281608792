import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CredentialsListComponent } from './dashboard/credentials/list/credentials-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HelpComponent } from './dashboard/help/help.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { StoreListComponent } from './dashboard/store/list/list.component';
import { SubscriptionComponent } from './dashboard/subscription/subscription.component';
import { UsersComponent } from './dashboard/users/users/users.component';
import { LoginComponent } from './site/login/login.component';
import { ListComponent } from './dashboard/companies/list/list.component';
import { CompanyDetailsComponent } from './dashboard/companies/details/details.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', component: ProfileComponent },
      { path: 'users', component: UsersComponent },
      { path: 'companies', component: ListComponent },
      { path: 'companies/:id/details', component: CompanyDetailsComponent },
      { path: 'help', component: HelpComponent},
      { path: "store", children: [
        { path: '', redirectTo: 'plans', pathMatch: 'full' },
        { path: 'plans', component: StoreListComponent },
      ]},
      {
        path: 'waste-register', loadChildren: () => import('./dashboard/waste-register/waste-register.module').then(m => m.WasteRegisterModule),
        data: {
          role: 'EVIDENCE'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'waste-cards', loadChildren: () => import('./dashboard/waste-cards/waste-cards.module').then(m => m.WasteCardsModule),
        canActivateChild: [AuthGuard]
      },
      {
        path: 'reports', loadChildren: () => import('./dashboard/reports/reports.module').then(m => m.ReportsModule),
        data: {
          role: 'REPORTS'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'subscription', component: SubscriptionComponent
      },
      {
        path: 'packaging', loadChildren: () => import('./dashboard/packaging/packaging.module').then(m => m.PackagingModule),
        canActivateChild: [AuthGuard]
      }
    ],
  },
  { path: 'login', component: LoginComponent },
  // { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
