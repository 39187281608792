import { ActivatedPackage } from "./activated-package.model"
import { BdoCredentials } from "../../companies/models/bdo-credentials.model"
import { CompanyInvoice } from "./company-invoice.model"
import { PersonInvoice } from "./person-invoice.model"
import { Profile } from "./profile.model"

export class CompanyProfile implements Profile{

  constructor(
    readonly username: string,
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly companyName: string,
    readonly packages: ActivatedPackage[],
    readonly mainAccountCreated: boolean,
    readonly credentials: BdoCredentials[],
    readonly marketingAgreement: boolean,
    readonly companyInvoice: CompanyInvoice,
    readonly personInvoice: PersonInvoice,
    readonly authorities: string[],
  ) {}

  getFullName(): string{
    return `${this.firstName} ${this.lastName}`
  }

  hasActivePackage(): boolean {
    return this.packages.some(x => x.activated);
  }
}
