<app-header-title title="Ustawienia" />

  <div class="flex-col flex lg:flex-row gap-4">
    <div class="bg-white dark:bg-gray-800 dark:text-white rounded-xl mx-2 shadow-md p-4 basis-1/2" *ngIf="!loading && getAccountType() === 'COMPANY'">
      <app-edit-company-profile-card  [companyProfile]="profile"></app-edit-company-profile-card>
    </div>
    <div class="bg-white dark:bg-gray-800 dark:text-white rounded-xl mx-2 shadow-md basis-1/2 p-4" *ngIf="!loading && getAccountType()">
      <app-edit-password-card ></app-edit-password-card>
    </div>
    <div class="bg-white dark:bg-gray-800 dark:text-white rounded-xl mx-2 shadow-md basis-1/2 p-4" *ngIf="!loading && getAccountType() === 'COMPANY'g">
      <app-profile-packages-list [packages]="profile?.packages"></app-profile-packages-list>
    </div>
  </div>
