import { Injectable } from "@angular/core";
import { Observable, tap, switchMap, of } from "rxjs";
import { BdoServicePage } from "src/app/services/bdoservice/common/model/bdo-service-page.model";
import { ComponentStore } from "@ngrx/component-store";

export interface FetchParams {
  page: number;
  pageSize: number;
  query?: string;
  sortBy?: string;
}

export interface GenericListState<T> {
  page: BdoServicePage<T> | null;
  fetchParams: FetchParams | null;
  loading: boolean;
  error?: any;
}

const defaultState = <T>(): GenericListState<T> => ({
  page: null,
  fetchParams: null,
  loading: true,
});

@Injectable()
export class GenericListComponentStore<T, P> extends ComponentStore<GenericListState<T>> {
  constructor(
    private readonly fetchPageStrategy: (data: FetchParams & { params?: P }) => Promise<BdoServicePage<T>>
  ) {
    super(defaultState<T>());
  }

  readonly page$ = this.select(state => state.page);
  readonly loading$ = this.select(state => state.loading);
  readonly error$ = this.select(state => state.error);
  readonly fetchParams$ = this.select(state => state.fetchParams);

  readonly addPage = this.updater((state: GenericListState<T>, page: BdoServicePage<T>) => ({
    page,
    loading: false,
    fetchParams: state.fetchParams,
    error: null,
  }));

  readonly setLoading = this.updater((state: GenericListState<T>) => ({
    page: null,
    loading: true,
    fetchParams: state.fetchParams,
    error: null,
  }));

  readonly setError = this.updater((state: GenericListState<T>, error: any) => ({
    ...state,
    loading: false,
    error,
  }));

  readonly getPage = this.effect((data$: Observable<FetchParams & { params?: P }>) => {
    return data$.pipe(
      tap(data => {
        this.patchState({
          fetchParams: data
        });
        this.setLoading();
      }),
      switchMap(data =>
        this.fetchPageStrategy(data)
          .then(page => this.addPage(page))
          .catch(error => this.setError(error))
      )
    );
  });

  readonly refresh = this.effect((trigger$: Observable<void>) => trigger$.pipe(
    switchMap(() =>
      this.fetchPageStrategy(this.get().fetchParams)
        .then(page => this.addPage(page))
        .catch(error => this.setError(error))
    )
  ));
}
