export class StoreProduct {
  constructor(
    public readonly id: number,
    public readonly parent_id: string,
    public readonly title: string,
    public readonly description: string,
    public readonly link: string,
    public readonly image_link: string,
    public readonly price: string,
    public readonly sale_price: string,
    public readonly duration: string,
    public readonly cards_number: number,
    public readonly show_in_apps: string,
    public readonly bdo_mobile_order: number,
  ) {}

  static getDurationDays(product: StoreProduct): number {
      switch (product.duration) {
        case "1 miesiąc":
          return 30;
        case "3 miesiące":
          return 90;
        case "6 miesięcy":
          return 180;
        case "1 rok":
          return 365;
        default:
          return 0;
      }
  }
}
