import { FormGroup } from "@angular/forms";

export function MarketingValidator(marketingSource: string, marketingSourceOther: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[marketingSource];
        const matchingControl = formGroup.controls[marketingSourceOther];
        let errors = null;

        if(control.value != '' && control.value != 'Inne') return;
  
        if (matchingControl.errors && !matchingControl.errors.marketingValidator) {
            return;
        }
  
        if (control.value === "Inne" && matchingControl.value === '') {
            errors = { marketingValidator: true }
        } 

        matchingControl.setErrors(errors);
        return errors;
    }
  }
  