<div class="flex align-items-center gap-2">
    <img
      src="https://ui-avatars.com/api/?size=128&background=random&name={{ companyUnit?.name }}"
      style="width: 18px"
      [alt]="companyUnit?.name"
    />
    <div class="flex gap-2 items-center">
      <p>{{ companyUnit?.name }}</p>
      <p-chip styleClass="text-xs" *ngIf="companyUnit.isHeadquarters" icon="pi pi-building" label="Siedziba" />
    </div>
  </div>