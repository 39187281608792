import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { StoreService } from 'src/app/services/bdoservice/store/store.service';
import { CompanyProfile } from 'src/app/services/bdoservice/users/models/company-profile.model';
import { UsersService } from 'src/app/services/bdoservice/users/users.service';
import { InvoiceType } from '../../profile/edit-company-invoice-card/edit-company-invoice-card.component';
import { StoreProduct } from 'src/app/services/bdoservice/store/models/store-product.model';
import { KeycloakService } from 'keycloak-angular';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { AccountService } from 'src/app/services/account.service';


@Component({
  selector: 'app-store-list',
  templateUrl: './list.component.html',

})
export class StoreListComponent implements OnInit {

adminIcon = faScrewdriverWrench;
companyProfile: CompanyProfile

canBuyService = false;

formTypes = [
  { name: "Osoba prywatna", value: InvoiceType.PERSON },
  { name: "Firma", value: InvoiceType.COMPANY },
];

productsListError = '';
productsLoading = true;
allProducts: StoreProduct[] = [];
filteredSubscriptions: StoreProduct[] = [];
filteredCards: StoreProduct[] = [];
actualPackageProduct: StoreProduct;

cardsPackageParentIds = ['32686'];

selectedDuration: string = null;
selectedCardsCount: number = null;

cardsCount: number[] = [];
durations: string[] = [];
customCount = 10;
customCountAdminEnabled = false;
disableBuy = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private usersService: UsersService,
    private storeService: StoreService,
    private keycloakService: KeycloakService,
    private accountService: AccountService,
    ) {}

  ngOnInit(): void {

    this.accountService.profile$.subscribe(res => {
      if(res.data){
        this.companyProfile = res.data as CompanyProfile;
      }
    });

    this.storeService.getProducts().then(products => {
      this.productsLoading = true;
      this.productsListError = '';
      this.allProducts = products;
      //sort by lower is first
      this.filteredSubscriptions = products.filter(product => this.getProductApps(product).includes('bdo-mobile') && product.price != '').sort((a, b) => a.price.localeCompare(b.price));
      this.filteredCards = products.filter(product => this.cardsPackageParentIds.includes(product.parent_id)).sort((a, b) => this.cardsPackageParentIds.indexOf(a.id.toString()) - this.cardsPackageParentIds.indexOf(b.id.toString()));

      this.durations = this.filteredCards.map(product => product.duration).filter((value, index, self) => self.indexOf(value) === index)
      this.selectedDuration = this.durations[0];

      this.cardsCount = this.filteredCards.filter(product => product.duration == this.selectedDuration).map(product => product.cards_number).filter((value, index, self) => self.indexOf(value) === index ).sort((a, b) => a - b);
      this.selectedCardsCount = this.cardsCount[0];

      this.actualPackageProduct = this.filteredCards.filter(product => product.duration === this.selectedDuration && product.cards_number === this.selectedCardsCount)[0];

    }).catch(error => {
      this.productsListError = error.error.message;
    }).finally(() => {
      this.productsLoading = false;
    });
  }

  private getProductApps(product: StoreProduct): string[]{
    //and replace all " to empty
    return product.show_in_apps.replace(/[[\]']+/g,'').replace(/"/g, '').split(',');
  }

  onChangeDuration(event: SelectButtonChangeEvent): void {
    this.selectedDuration = event.value;

    if(!this.customCountAdminEnabled){
      this.cardsCount = this.filteredCards.filter(product => product.duration == this.selectedDuration).map(product => product.cards_number).filter((value, index, self) => self.indexOf(value) === index ).sort((a, b) => a - b);
      this.selectedCardsCount = this.cardsCount[0];
      this.changeProduct()
    } else {
      const storeProduct = this.getAdminProduct();
      this.actualPackageProduct = storeProduct;
    }
  }

  onChangeCardsCount(event: SelectButtonChangeEvent): void {
    this.selectedCardsCount = event.value;
    this.changeProduct()
  }

  onCustomCountAdminEnabledChange(){
    if(!this.customCountAdminEnabled){
      this.actualPackageProduct = this.filteredCards.filter(product => product.duration === this.selectedDuration && product.cards_number === this.selectedCardsCount)[0];
    } else {
      const storeProduct = this.getAdminProduct();
      this.actualPackageProduct = storeProduct;
    }
  }

  onCustomCountChange(): void {
    const storeProduct = this.getAdminProduct();
    this.actualPackageProduct = storeProduct;
  }

  changeProduct(): void {
    this.actualPackageProduct = this.filteredCards.filter(product => product.duration === this.selectedDuration && product.cards_number === this.selectedCardsCount)[0];
  }

  isAdmin(): boolean {
    return this.keycloakService.getUserRoles()?.includes('ADMIN')
  }

  private getAdminProduct(): StoreProduct {
    return new StoreProduct(
      -1,
      '-1',
      `Pakiet ${this.customCount} kredytów`,
      "Pakiet administracyjny",
      "",
      "https://ekowitryna.pl/wp-content/uploads/2023/08/bdo_mobile_ekowitryna.png",
      "0",
      "",
      this.selectedDuration,
      this.customCount,
      "",
      1
    );
  }

}
