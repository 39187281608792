import { Component } from '@angular/core';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',

})
export class SubscriptionComponent {

  openMoreInfo(){
    //open website in new window
    document.location.href = "https://ekowitryna.pl";

  }

  openBuyNow(){
    document.location.href = "https://ekowitryna.pl/produkt/ekowitryna-starter/";
  }

}
