import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',

})
export class MenuComponent implements OnInit {

  @Input()
  public items: MenuItem[] = [];

  private currentRoute: string = this.router.url;

  constructor(
    readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.expandItemsIfRouterActive();
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
      this.expandItemsIfRouterActive();
    });
  }

  isParentMenuOptionActive(item: MenuItem): boolean {
    if(item.items){
      return item.items.some(child => child.routerLink == this.currentRoute)
    }

    if(item.routerLink == this.currentRoute){
      return true;
    }

    return false;
  }

  expandItemsIfRouterActive(){
    this.items.forEach(item => {
      if(item.items){
        item.items.forEach(child => {
          if(child.routerLink == this.currentRoute){
            item.expanded = true;
          }
        })
      }
    })
  }
}
