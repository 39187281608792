import { Component, OnInit } from '@angular/core';
import { ValidatorFn, Validators, FormControl, FormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EditEmployeeRequest, EmployeeRequest } from '../users/users.component';
import { AccountValidators } from 'src/app/services/bdoservice/constants';
import { UsersService } from 'src/app/services/bdoservice/users/users.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { KeycloakValidators } from 'src/app/common/validators/keycloak-validators/keycloak-validators';
import { MessageService } from 'primeng/api';
import { ConfirmedValidator } from 'src/app/common/validators/confirmed-password-validator/confirmed-password.validator';
import { AccountService } from 'src/app/services/account.service';
import { BdoCredentials } from 'src/app/services/bdoservice/companies/models/bdo-credentials.model';
import { CompanyService } from 'src/app/services/bdoservice/companies/company.service';
import { CompanyBdoCredentials } from 'src/app/services/bdoservice/companies/models/company-bdo-credentials';
import { select, Store } from '@ngrx/store';
import { selectCompanies } from 'src/app/common/ui/company-context-select/company-context.selectors';
import { Company } from 'src/app/services/bdoservice/companies/models/company.model';
import { PickListSourceSelectEvent } from 'primeng/picklist';

export class AddUserDialogData {
  firstName = ""
  lastName = ""
  username = ""
  email = ""
  password = ""
  password2 = ""
  credentials: CompanyBdoCredentials[] = []
  operationType: OperationType = OperationType.CREATE_USER

}

export enum OperationType {
  CREATE_USER = "CREATE_USER",
  EDIT_USER = "EDIT_USER",
  CREATE_FIRST_USER = "CREATE_FIRST_USER",
  CHANGE_PASSWORD = "CHANGE_PASSWORD"
}

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',

})
export class AddDialogComponent implements OnInit {
  form: FormGroup;
  formError = '';

  separatorKeysCodes: number[] = [ENTER, COMMA];
  allCredentials: CompanyBdoCredentials[] = [];
  credentials: CompanyBdoCredentials[] = [];
  titleText = "Tworzenie użytkownika mobilnego";

  passwordHide = true;
  loading = false;
  companies: Company[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<AddUserDialogData>,
    private usersService: UsersService,
    private messageService: MessageService,
    private accountService: AccountService,
    private companyService: CompanyService,
    private store: Store
    ) { 
      this.store.pipe(select(selectCompanies)).subscribe(companies => {
        this.companies = companies.map(company => company.company);
      });
    }

  isEdit(): boolean {
    if (this.config.data?.operationType === OperationType.EDIT_USER) return true;
    return false
  }

  ngOnInit() {
    const passwordValidators: ValidatorFn[] = [];
    const password2Validators: ValidatorFn[] = [];

    if (this.isEdit()) {
      this.titleText = "Edycja użytkownika mobilnego";
    }
    else {
      password2Validators.push(Validators.required)
      passwordValidators.push(Validators.required, Validators.minLength(AccountValidators.MIN_PASSWORD_LENGTH), Validators.maxLength(AccountValidators.MAX_PASSWORD_LENGTH), KeycloakValidators.passwordValidator())
    }

    this.form = new FormGroup({
      firstName: new FormControl<string>(this.config.data?.firstName, [Validators.required]),
      lastName: new FormControl<string>(this.config.data?.lastName, [Validators.required]),
      username: new FormControl<string>({ value: this.config.data?.username, disabled: this.isEdit() }, [Validators.required, Validators.pattern(AccountValidators.USERNAME_PATTERN)]),
      password: new FormControl<string>('', passwordValidators),
      password2: new FormControl<string>('', password2Validators),
    }, { validators: [ConfirmedValidator('password', 'password2', this.isEdit())] })

    this.credentials = this.config.data?.credentials;

    this.companyService.getAllCredentials().then(res => {
      //sortByIsPrimary
      this.allCredentials = res.filter((credential) => {
        return !this.credentials.some((selectedCredential) => { return selectedCredential.id === credential.id })
      }).sort((a, b) => {
        return this.sortByIsPrimary(a, b)
      });
      
    })
    .catch(err => {
      this.setFormError(err.message || "Wystąpił nieoczekiwany błąd podczas pobierania uprawnień")
    });
  }

  sortByIsPrimary(a: CompanyBdoCredentials, b: CompanyBdoCredentials): number {
    if (a.isPrimary && !b.isPrimary) {
      return -1;
    } else if (!a.isPrimary && b.isPrimary) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  }

  onNoClick(): void {
    this.ref.close();
  }

  isFirstConfiguration(): boolean {
    return this.config.data?.operationType === OperationType.CREATE_FIRST_USER
  }

  getFirstNameError(): string {
    const field = this.form.get('firstName');
    return field.hasError('required') ? 'Pole jest wymagane.' : '';
  }

  getNameTooltip(credentials: CompanyBdoCredentials): string {
    if(credentials.isPrimary){
      return "Klucz główny"
    } else {
      return "Klucz dodatkowy"
    }
  }

  getCompanyName(credentials: CompanyBdoCredentials): string {
    return this.companies.find(company => company.id === credentials.companyId)?.name || ""
  }

  getLastNameError(): string {
    const field = this.form.get('firstName');
    return field.hasError('required') ? 'Pole jest wymagane.' : '';
  }

  getUsernameError(): string {
    const field = this.form.get('username');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('pattern')) {
      return 'Nazwa użytkownika musi się składać z liter, cyfr oraz podkreślnika lub kropki.';
    }

    return '';
  }

  getEmailError(): string {
    const field = this.form.get('email');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('email')) {
      return 'Nieprawidłowy adres e-mail.';
    }

    return '';
  }

  getPasswordError(): string {
    const field = this.form.get('password');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('minlength')) {
      return `Pole musi składać się z min. ${AccountValidators.MIN_PASSWORD_LENGTH} znaków.`;
    }

    if (field.hasError('uppercase')) {
      return 'Pole musi zawierać co najmniej jedną wielką literę.';
    }

    if (field.hasError('specialCharacter')) {
      return 'Pole musi zawierać co najmniej jeden znak specjalny.';
    }

    return '';
  }

  getPassword2Error(): string {
    const field = this.form.get('password2');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('confirmedValidator')) {
      return "Hasła nie są identyczne.";
    }

    return '';
  }

  editEmployee(data: AddUserDialogData) {
    const employee = new EditEmployeeRequest(
      data.firstName,
      data.lastName,
      data.username.toLocaleLowerCase(),
      this.credentials.map((credential) => { return credential.id })
    );

    this.usersService.editEmployee(employee).then(() => {
      this.loading = true;
      this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Użytkownik został zaktualizowany.' });
      this.ref.close()
    })
      .catch(err => {
        this.setFormError(err.message || "Wystąpił nieoczekiwany błąd podczas zakładania użytkownika")
      })
      .finally(() => {
        this.loading = false;
      });
  }

  isRequired(controlName: string): boolean {
    return this.form.controls[controlName].hasValidator(Validators.required);
  }

  saveEmployee(data: AddUserDialogData) {
    const employee = new EmployeeRequest(
      data.firstName,
      data.lastName,
      data.username.toLocaleLowerCase(),
      data.password,
      this.credentials.map((credential) => { return credential.id })
    );

    this.usersService.saveEmployee(employee).then(() => {
      this.loading = true;
      this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Użytkownik został dodany.' });

      this.ref.close()
    })
      .catch(err => {
        this.setFormError(err.message || "Wystąpił nieoczekiwany błąd podczas zakładania użytkownika")
      }).finally(() => {
        this.loading = false;
      });
  }

  
  onMoveToTarget(event: PickListSourceSelectEvent) {
    const uniqueCompanyIds = new Set<string>();

    // Przeszukiwanie listy credentials, aby wypełnić początkowy zestaw unikalnych companyId
    this.credentials.forEach(item => uniqueCompanyIds.add(item.companyId));

    // Iteracja przez elementy event.items, aby dodać unikalne companyId
    event.items.forEach(item => {
      uniqueCompanyIds.add(item.companyId);
    });

    // Usuwanie duplikatów z listy credentials i przenoszenie ich z powrotem do allCredentials
    this.credentials = this.credentials.filter(item => {
      if (uniqueCompanyIds.has(item.companyId)) {
        uniqueCompanyIds.delete(item.companyId);
        return true;
      } else {
        this.allCredentials.push(item);
        return false;
      }
    });

    this.allCredentials = this.allCredentials.sort((a, b) => {
      return this.sortByIsPrimary(a, b)
    });
  }

  isLocked(credential: CompanyBdoCredentials): boolean {
    return this.credentials.some((selectedCredential) => { return selectedCredential.companyId === credential.companyId }) && !this.credentials.includes(credential)
  }

  saveForm() {
    this.setFormError(null)
    if (this.isEdit()) {
      this.editEmployee(this.form.getRawValue());
    } else {
      this.saveEmployee(this.form.getRawValue());
    }
  }

  setFormError(message: string) {
    this.formError = message
  }

}
