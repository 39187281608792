<div class="flex flex-wrap mt-4 gap-4 justify-center mb-4">
  <!-- Create 2 buttons - one with link and icon of bdomobile. Second to youtube with youtube logo and colors-->
  <p-button
    aria-label="Instrukcja"
    styleClass="text-white bg-blue-600"
    (onClick)="openWikiPage()"
  >
    <i class="pi pi-flag"></i> Instrukcja
  </p-button>

  <p-button
    styleClass="text-white bg-red-600"
    aria-label="Film instruktażowy"
    (onClick)="openTutorialVideo()"
  >
    <i class="pi pi-play"></i> Film instruktażowy
  </p-button>

  <p-button
    styleClass="text-white bg-green-700"
    aria-label="Napisz do nas"
    (onClick)="openContactMail()"
  >
    <i class="pi pi-envelope"></i> Napisz do nas
  </p-button>
</div>
