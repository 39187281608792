import { Component, Input, OnInit } from '@angular/core';
import { ActivatedPackage } from 'src/app/services/bdoservice/users/models/activated-package.model';

@Component({
  selector: 'app-profile-packages-list',
  templateUrl: './packages-profile-list.component.html',
})
export class PackagesProfileListComponent implements OnInit {

  @Input()
  packages: ActivatedPackage[] = [];

  sortedPackages: ActivatedPackage[] = [];

  ngOnInit(): void {
    this.sortPackages();
  }

  sortPackages(): void {
    this.sortedPackages = this.packages?.sort((a, b) => {
      if (a.activated) {
        return -1;
      } else if (b.activated) {
        return 1;
      } else {
        return b.createDate.getTime() - a.createDate.getTime();
      }
    });
  }

  getStatusText(_package: ActivatedPackage): string {
    if (_package.activated) {
      return 'Aktywny';
    } else {
      return 'Nieaktywny';
    }
  }

  getOperationCountText(_package: ActivatedPackage): string {
    if (_package.operationCount == null) {
      return 'Bez limitu';
    } else {
      return _package.operationCount.toString();
    }
  }
}
