<div>
    <p class="mt-2"><strong>NIP:</strong> {{ company.nip }}</p>
    <p class="mt-2"><strong>NIP EU:</strong> {{ company.nipEu }}</p>
    <p class="mt-2"><strong>Numer rejestracyjny BDO:</strong> {{ company.bdoRegistrationNumber }}</p>
    
    <div *ngIf="company.address">
        <h3 class="text-lg font-semibold mt-4">Adres:</h3>
        <p class="mt-2">{{ company.address.street }} {{ company.address.houseNumber }}, {{ company.address.apartmentNumber }}</p>
        <p class="mt-2">{{ company.address.postalCode }} {{ company.address.city }}</p>
        <p class="mt-2">{{ company.address.voivodeship }}, {{ company.address.province }}</p>
    </div>

  </div>