import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Employee } from 'src/app/services/bdoservice/users/models/employee.model';
import { UsersService } from 'src/app/services/bdoservice/users/users.service';
import { AddDialogComponent, OperationType, AddUserDialogData } from '../add-dialog/add-dialog.component';
import { ChangePasswordComponent, ChangePasswordDialogData } from '../change-password/change-password.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, map, shareReplay } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AccountService } from 'src/app/services/account.service';
import { BdoCredentials } from 'src/app/services/bdoservice/companies/models/bdo-credentials.model';
import { Store } from '@ngrx/store';
import { CompanyBdoCredentials } from 'src/app/services/bdoservice/companies/models/company-bdo-credentials';
import { CompanyService } from 'src/app/services/bdoservice/companies/company.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  providers: [DialogService, ConfirmationService]
})
export class UsersComponent implements OnInit{

  MAX_BDO_CREDENTIALS_NAME_LENGTH = 13;

  users: EmployeeListItem[] = [];
  removeLoading: Map<string, boolean> = new Map<string, boolean>();
  loading = false;

  addDialogRef: DynamicDialogRef | undefined;
  changePasswordDialogRef: DynamicDialogRef | undefined;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay(),
  );

  isHandset = false;

 constructor(
  private usersService: UsersService,
  private breakpointObserver: BreakpointObserver,
  private dialogService: DialogService,
  private messageService: MessageService,
  private confirmationService: ConfirmationService,
  private companyService: CompanyService,
  private store: Store
  ){
  this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
    if(result.matches){
      this.MAX_BDO_CREDENTIALS_NAME_LENGTH = 5;
    } else {
      this.MAX_BDO_CREDENTIALS_NAME_LENGTH = 13;
    }
  });
 }

  companyBdoCredentials: CompanyBdoCredentials[] = []

  ngOnInit(): void {
    this.companyService.getAllCredentials().then(res => {
      this.companyBdoCredentials = res;      
    })
    .catch(err => {
      console.error(err);
    });

    this.isHandset$.subscribe(isHandset => {
      this.isHandset = isHandset;
    });

    this.refresh();
  }

  refresh(){
    this.loading = true;
    this.usersService.getEmployees().then((list: Employee[]) => {
      this.users = list.map(x => EmployeeListItem.buildFromEmployee(x, this.companyBdoCredentials));
    }).finally(() => {
      this.loading = false;
    });
  }

  showCreateDialog(){
    const data = new AddUserDialogData();
    data.operationType = OperationType.CREATE_USER;

    this.addDialogRef = this.dialogService.open(AddDialogComponent, {
      data: data,
      header: 'Nowy użytkownik',
      width: this.isHandset ? '100%' : '50%'
    });

    this.addDialogRef.onClose.subscribe(() => {
      this.refresh()
    });
  }

  getCredentialsNames(items: BdoCredentials[]): string[]{
    return items.slice(1).map(x => x.name)
  }

  getFirstCredentials(user: EmployeeListItem): string{
    return user.credentials.length > 0 ? user.credentials[0].name : 'Brak';
  }

  showEditDialog(user: EmployeeListItem){
    const data = new AddUserDialogData();
    data.firstName = user.firstName;
    data.lastName = user.lastName;
    data.username = user.username;
    data.credentials = this.companyBdoCredentials.filter(x => user.credentials.map(x => x.id).includes(x.id));
    data.operationType = OperationType.EDIT_USER;

    this.addDialogRef = this.dialogService.open(AddDialogComponent, {
      data: data,
      header: 'Edycja użytkownika',
      width: this.isHandset ? '100%' : '50%'
    });

    this.addDialogRef.onClose.subscribe(() => {
      this.refresh()
    });
  }

  showChangePasswordDialog(user: EmployeeListItem){
    const data = new ChangePasswordDialogData();
    data.username = user.username;

    this.changePasswordDialogRef = this.dialogService.open(ChangePasswordComponent, {
      data: data,
      header: 'Zmiana hasła',
      width: 'fit-content'
    });

    this.changePasswordDialogRef.onClose.subscribe(() => {
      this.refresh()
    });
  }

  getRemoveLabel(): string {
    return this.isHandset ? '' : 'Usuń'
  }

  removeUser(event: Event, user: EmployeeListItem){

    this.confirmationService.confirm({
      target: event?.target,
      message: `Czy na pewno chcesz usunąć konto użytkownika: ${user.username}? `,
      accept: () => {
        this.removeLoading.set(user.username, true);

        this.usersService.removeEmployee(user.username).then(() => {
          this.messageService.add({severity:'success', summary:'Sukces', detail:'Użytkownik został usunięty'});
          this.refresh();
        }).catch(() => {
          this.messageService.add({severity:'error', summary:'Błąd', detail:'Nie udało się usunąć użytkownika'});
        }).finally(() => {
          this.removeLoading.set(user.username, false);
        });
      }
    });
  }
}

export class EmployeeRequest{
 constructor(
  private firstName: string,
  private lastName: string,
  private username: string,
  private password: string,
  private credentialsIds: string[] = []
 ){}
}

export class EditEmployeeRequest{
  constructor(
    private firstName: string,
    private lastName: string,
    private username: string,
    private credentialsIds: string[] = []
  ){}
}

export class EmployeeListItem{

  constructor(
    public firstName: string,
    public lastName: string,
    public username: string,
    public credentials: BdoCredentials[],
    public isCompanyAccount: boolean){

  }

  static buildFromEmployee(employee: Employee, credentials: BdoCredentials[]): EmployeeListItem{
    return new EmployeeListItem(
      employee.firstName,
      employee.lastName,
      employee.username,
      employee.credentials,
      employee.mainAccountCreated
    )
  }
}
