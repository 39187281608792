<p-confirmPopup></p-confirmPopup>
<div class="flex items-center gap-2 justify-end">
  <span class="p-input-icon-right">
    <i class="pi pi-search" *ngIf="(loading$ | async) === false && !searchMode" pTooltip="Szukaj" (click)="search" (keyup.enter)="search()" tabindex="1"></i>
    <i class="pi pi-spin pi-spinner" *ngIf="(loading$ | async) === true"></i>
    <!-- x icon clear -->
    <i class="pi pi-times" *ngIf="searchMode && (loading$ | async) === false " (click)="clearSearch()" pTooltip="Wyczyść" (keyup.enter)="clearSearch()" tabindex="2"></i>
    <input [disabled]="(loading$ | async)" pInputText type="text" placeholder="Szukaj" class="border border-gray-300 rounded-md p-3" [(ngModel)]="searchText" (keyup.enter)="search()" tabindex="0"/>
  </span>
  <p-button label="Dodaj nowy klucz" icon="pi pi-plus" (onClick)="showCreateDialog()"></p-button>
</div>
<div class="mt-4">
  <p-table [value]="credentials$ | async" class="mb-4 z-index-20 w-full"
  [paginator]="true"
  [rows]="(page$ | async)?.size"
  [totalRecords]="(page$ | async)?.totalElements"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [rows]="15"
  [showCurrentPageReport]="true"
  styleClass="p-datatable-striped p-datatable-gridlines"
  responsiveLayout="stack"
  [breakpoint]="'960px'"
  [loading]="loading$ | async"
  sortField="isPrimary"
  [sortOrder]="-1"
  >
    <ng-template pTemplate="header">
      <tr>
          <th scope="col" pSortableColumn="name">Nazwa <p-sortIcon field="name"></p-sortIcon></th>
          <th scope="col" pSortableColumn="isPrimary">Typ <p-sortIcon field="isPrimary"></p-sortIcon></th>
          <th scope="col">ClientId</th>
          <th scope="col">ClientSecret</th>
          <th scope="col">Akcje</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-credentials>
      <tr>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <span class="p-column-title font-semibold">Nazwa</span>
            <div class="flex flex-row items-center gap-2">
              <img
              src="https://ui-avatars.com/api/?size=128&background=random&name={{
                credentials?.name
              }}"
              style="width: 18px"
              [alt]="credentials?.name"
            />
            <p>
              {{ credentials.name }}
            </p>
            </div>
            </td>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <!-- gold key with Klucz główny firmy if primary -->
            <span class="flex gap-2 text-yellow-500" *ngIf="credentials.isPrimary">
              <i class="pi pi-key"></i>Klucz główny
            </span>
            <span class="flex gap-2" *ngIf="!credentials.isPrimary">
              <i class="pi pi-key"></i>Klucz dodatkowy
            </span>
            <p>{{ credentials.type }}</p>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <span class="p-column-title font-semibold">ClientID</span>
            <p class="font-mono text-sm">{{ credentials.clientId }}</p>
          </td>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <span class="p-column-title font-semibold">ClientSecret</span>
            <app-secret-value-field [value]="credentials.clientSecret"></app-secret-value-field></td>
          <td [ngClass]="{'flex flex-col items-start': isHandset$ | async}">
            <div class="flex flex-row gap-2">
              <p-button label="Edytuj" icon="pi pi-pencil" (onClick)="showEditDialog(credentials)"></p-button>
              <p-button *ngIf="!credentials.isPrimary" label="Usuń" icon="pi pi-trash" severity="danger" (onClick)="removeCredentials($event, credentials)" [loading]="getRemoveLoading(credentials)"></p-button>
            </div>
          </td>
      </tr>
  </ng-template>
  </p-table>
</div>
