
export class PackageInfo{
    constructor(
     readonly name: string,
     readonly state: number,
     readonly max: number,
     readonly endDate: Date,
     readonly isActive: boolean
    ) {}
}
