import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

import { CompanyProfile, UsersService, EditCompanyProfile } from 'src/app/services/bdoservice/users/users.service';

@Component({
  selector: 'app-edit-company-profile-card',
  templateUrl: './edit-company-profile-card.component.html',

})
export class EditCompanyProfileCardComponent implements OnInit {

  form: FormGroup;
  editProfileError = '';

  @Input()
  companyProfile: CompanyProfile

  saving = false;

  constructor(
    private usersService: UsersService,
    private messageService: MessageService,
  ){}

  ngOnInit(): void {
    this.form = new FormGroup({
      firstName: new FormControl(this.companyProfile?.firstName, Validators.required),
      lastName: new FormControl(this.companyProfile?.lastName, Validators.required),
      email: new FormControl({value: this.companyProfile?.username, disabled: true}),
      companyName: new FormControl(this.companyProfile?.companyName),
      marketingAgreement: new FormControl(this.companyProfile?.marketingAgreement)
    });
  }

  submitProfile(){
    this.editProfileError = '';
    this.saving = true;
    const formValue = this.form.value
    const request = new EditCompanyProfile(formValue.firstName, formValue.lastName, formValue.companyName, formValue.marketingAgreement)

    this.usersService.editCompanyProfile(request).then(() => {
      this.messageService.add({severity:'success', summary:'Sukces', detail:'Profil został zaktualizowany!'});
    }).catch(err => {
      this.editProfileError = err.console.error;
    }).finally(() => {
      this.saving = false;
    });
  }

  getError(controlName: string): string {
    const control = this.form.controls[controlName];
    if(control.errors?.required){
      return 'To pole jest wymagane';
    }
    return '';
  }
}
