import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';
import { CompanyService } from 'src/app/services/bdoservice/companies/company.service';
import { Company } from 'src/app/services/bdoservice/companies/models/company.model';

@Component({
  selector: 'app-company-info',
  templateUrl: './details.component.html',
})
export class CompanyInfoComponent implements OnInit {
  
  @Input() companyId: string;

  company: Company

  constructor(
    private companyService: CompanyService,
  ){}

  ngOnInit(){
    this.companyService.getCompany(this.companyId).then(company => this.company = company);
  }
}
