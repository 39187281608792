import { Component, Input } from '@angular/core';
import { CompanyUnit } from 'src/app/services/bdoservice/companies/models/company-unit.model';

@Component({
  selector: 'app-company-unit-dropdown-item',
  templateUrl: './company-unit-dropdown-item.component.html',
})
export class CompanyUnitDropdownItemComponent {

  @Input()
  companyUnit: CompanyUnit
}
