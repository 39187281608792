import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ChangeEmployeePasswordRequest } from 'src/app/services/bdoservice/users/models/change-employee-password-request.model';
import { UsersService } from 'src/app/services/bdoservice/users/users.service';
import { KeycloakValidators } from 'src/app/common/validators/keycloak-validators/keycloak-validators';
import { AccountValidators } from 'src/app/services/bdoservice/constants';
import { MessageService } from 'primeng/api';
import { ConfirmedValidator } from 'src/app/common/validators/confirmed-password-validator/confirmed-password.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',

})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  formError = '';
  saving = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<ChangePasswordDialogData>,
    private usersService: UsersService,
    private messageService: MessageService) { }


  passwordHide = true;

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      username: new FormControl({ value: this.config.data?.username, disabled: true }),
      password: new FormControl('', [Validators.required, Validators.minLength(AccountValidators.MIN_PASSWORD_LENGTH), KeycloakValidators.passwordValidator()]),
      password2: new FormControl('', [Validators.required])
    }, { validators: ConfirmedValidator('password', 'password2', false) });
  }

  onNoClick(): void {
    this.ref.close();
  }

  changePassword() {
    this.saving = true;

    const request = new ChangeEmployeePasswordRequest(
      this.config.data?.username,
      this.changePasswordForm.controls.password.value,
      this.changePasswordForm.controls.password2.value,
    )

    this.usersService.changeEmployeePassword(request).then(() => {
      this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Hasło zostało zmienione!' });
      this.ref.close()
    }).catch(err => {
      this.setFormError(err.message || "Wystąpił nieoczekiwany błąd podczas zakładania użytkownika")
      this.saving = false;
    });
  }

  setFormError(message: string) {
    this.formError = message
  }

  getPasswordError(): string {
    const field = this.changePasswordForm.get('password');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('minlength')) {
      return `Pole musi składać się z min. ${AccountValidators.MIN_PASSWORD_LENGTH} znaków.`;
    }

    if (field.hasError('uppercase')) {
      return 'Pole musi zawierać co najmniej jedną wielką literę.';
    }

    if (field.hasError('specialCharacter')) {
      return 'Pole musi zawierać co najmniej jeden znak specjalny.';
    }

    return '';
  }

  getPassword2Error(): string {
    const field = this.changePasswordForm.get('password2');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('confirmedValidator')) {
      return "Hasła nie są identyczne.";
    }

    return '';
  }
}

export class ChangePasswordDialogData {
  username = ""
}
