import { Component, OnInit } from '@angular/core';

import { AccountService } from 'src/app/services/account.service';
import { Profile } from 'src/app/services/bdoservice/users/models/profile.model';
import { AccountType } from 'src/app/services/bdoservice/users/users.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',

})
export class ProfileComponent implements OnInit {

  profile: Profile;
  loading: boolean = false;

  constructor(
    private accountService: AccountService
  ) { }

  getAccountType(): AccountType {
    return this.accountService.getAccountType();
  }

  ngOnInit(): void {
    this.accountService.profile$.subscribe(res => {
      if(res.data){
        this.profile = res.data;
      }

      this.loading = res.loading;
    });
  }
}
