import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreProduct } from './models/store-product.model';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private http: HttpClient) { }

  getProducts(): Promise<StoreProduct[]> {
    const source = this.http.post<StoreProduct[]>(BASE_URL + 'accounts/store/plans', {});
    return lastValueFrom(source);
  }
}


