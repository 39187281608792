<app-header-title title="Pomoc"/>
<div class="bg-white dark:bg-gray-800 rounded-lg shadow-md p-2 mx-4">
  <h2 class="text-xl dark:text-white font-semibold">Chcesz zgłosić błąd lub zasugerować zmianę?</h2>
  <p class="mb-4 dark:text-white ">Jeśli chcesz się z nami skontaktować, możesz to zrobić wysyłając wiadomość na adres e-mail: <a href="mailto:support@mobiletract.com">support&#64;mobiletract.com</a> lub korzystając z naszego formularza kontaktowego.</p>  <div class="flex flex-col gap-2">
    <p-button *ngFor="let item of helpItems" type="submit" styleClass="w-full" color="primary" (onClick)="goToUrl(item.url)" [label]="item.name">
      <ng-template pTemplate="iconTemplate">
        <div>
          <fa-icon [icon]="item.icon"></fa-icon> {{ item.name }}
        </div>
      </ng-template>
    </p-button>
  </div>
</div>
