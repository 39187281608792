<app-header-title title="Brak dostępu"></app-header-title>
<div class="bg-white dark:bg-gray-800 dark:text-white mx-2 rounded-lg shadow-md p-2">
  <!-- Generate website marketing about buy a new subscription -->
  <div class="flex flex-col gap-2">
    <img class="w-fit" src="https://ekowitryna.pl/wp-content/uploads/2023/02/proeko_logo_ekowitryna_01-e1675433922637.png" alt="Ekowitryna.pl" />
    <p class="subscribers-only">Dostęp płatny!</p>

    <p>Możliwość korzystania z naszych modułów kart, raportowania i ewidencji wymaga wykupienia dostępu. Zapraszamy do zapoznania się z naszą ofertą subskrypcji lub skontaktowania się z nami w celu zamówienia dostępu do konkretnego modułu.</p>

    <p>
      Czy zależy Ci na tym, aby oszczędzić czas i uprościć prowadzenie ewidencji odpadów w Twojej firmie? A może
      chcesz mieć pewność, że Twoje dane są zawsze aktualne i zgodne z ministerialnym BDO? Potrzebujesz
      narzędzia, które pozwoli Ci na przeglądanie, filtrowanie, sortowanie i analizowanie danych w Twojej ewidencji?
    </p>
    <p>
      Mamy dla Ciebie rozwiązanie - esubskrypcję Ekowitryny! Dzięki tym modułom możesz zaoszczędzić czas i uniknąć
      błędów w procesie ewidencjonowania odpadów, a jednocześnie mieć pewność, że Twoja ewidencja jest zawsze zgodna
      z
      ministerialnym BDO.
    </p>
    <p>
      Moduł ewidencji pozwala w łatwy sposób ewidencjonować karty odpadów w ministerialnym systemie BDO, dodawać
      wpisy jako przejmujący oraz przekazujący, a także pobierać wydruki dla kart ewidencji. Co więcej, moduł raportowy
      umożliwia wygenerowanie raportów z kart odpadów oraz wpisów w ewidencji, które ułatwiają przygotowanie
      sprawozdań.
    </p>
    <p>
      Dzięki subskrypcji zyskujesz również dostęp do funkcjonalności, takich jak wyszukiwanie zduplikowanych wpisów,
      weryfikacja poprawności danych oraz możliwość pracy bezpośrednio na danych z ministerialnego BDO.
    </p>
    <p>Moduł kart pozwoli Ci:</p>
    <ul class="list-disc ml-6">
      <li>Zarządzać kartami w rolach: przekazujący, przejmujący, transportujący.</li>
      <li>Masowo zmieniać statusy kart, co przyśpieszy Twoją pracę.</li>
      <li>W prosty sposób tworzyć nowe karty odpadów oraz kopiować je.</li>
    </ul>
    <p>
      Moduł raportowy umożliwi Ci:
    </p>
    <ul class="list-disc ml-6">
      <li>Wyeksportowanie jednego pliku (csv) z całą ewidencją odpadów, zawierającą kod odpadu i miejsce prowadzenia
        działalności. Dzięki temu łatwo będziesz mógł przejrzeć całą ewidencję odpadów.</li>
      <li>Możliwość zaimportowania pliku do arkusza kalkulacyjnego (Excel) i edycja danych według własnych potrzeb,
        włączając przeglądanie, filtrowanie, sortowanie, agregowanie, analizowanie i weryfikowanie danych.</li>
      <li>Bezpośrednia praca na danych z ministerialnego systemu BDO umożliwia Ci weryfikację poprawności danych, co
        pozwala uniknąć błędów w ewidencji odpadów.</li>
      <li>Możesz również weryfikować poprawność danych pomiędzy ministerialnym BDO a Twoją ewidencją księgową,
        dzięki czemu możesz mieć pewność, że Twoja ewidencja odpadów jest zgodna z wymaganiami prawnymi.</li>
      <li>Moduł raportowy pomaga Ci w przygotowaniu danych pod sprawozdania roczne.</li>
      <li>Wyszukiwanie zduplikowanych wpisów i nieprawidłowości w masie lub kodzie odpadu, pozwala na szybkie i
        skuteczne zarządzanie ewidencją odpadów.</li>
      <li>Korzystanie z modułu raportowania to inwestycja w przyszłość Twojej firmy, która pozwoli Ci na skuteczne
        zarządzanie ewidencją odpadów i uniknięcie niepotrzebnych błędów.</li>
    </ul>
    <p>
      Moduł ewidencji pozwoli Ci:
    </p>
    <ul class="list-disc ml-6">
      <li>Zaoszczędź czas - wpis jednocześnie w wielu sekcjach dla wybranej karty odpadów.</li>
      <li>Dodawanie wpisów do KEO i KEOK w prosty sposób.</li>
      <li>Automatyczny rozkład masy odpadu w sekcji "Wytworzone" dla wybranego zakresu dni.</li>
    </ul>
    <p>Ale to nie wszystko! Decydując się na subskrypcję, otrzymujesz nie tylko dostęp do modułów raportów i
      ewidencji, ale również wiele innych korzyści:</p>
    <ul class="list-disc ml-6">
      <li>Wsparcie w nauce i posługiwaniu się BDO i KOBiZE i innymi systemami informatycznymi, zarówno dla
        początkujących jak i zaawansowanych użytkowników.</li>
      <li>Zamknięte forum GOZ, gdzie zadasz pytanie ekspertom, prześledzisz dostępne wątki z pytaniami i
        odpowiedziami, nawiążesz kontakty biznesowe z innymi użytkownikami.</li>
      <li>Dostęp do webinarów, na których poruszamy zawsze bieżące tematy dot. ochrony środowiska i zmian przepisów.
      </li>
      <li>Dla stałych subskrybentów szerokie rabaty na usługi bezpośredni takie jak konsultacje zdalne, audyty
        stacjonarne czy uczestnictwo w kontroli WIOŚ.</li>
      <li>Dostęp do kalkulatora KOBiZE.</li>
      <li>Stały rozwój funkcjonalności portalu w obrębie odpadów, opakowań i emisji!</li>
    </ul>
    <p>Zyskaj nie tylko najlepsze narzędzie do zarządzania odpadami, ale również wsparcie ekspertów oraz dostęp do
      szeregu dodatkowych funkcjonalności i rabatów na usługi bezpośrednie. Dołącz do naszej społeczności i
      przekonaj
      się, jak wiele możesz zyskać!
    </p>

    <p>Jeśli masz jakiekolwiek pytania, odwiedź stronę <a class="text-sky-400 after:content-['_↗']" href="https://ekowitryna.pl">ekowitryna.pl</a> lub napisz
      na
      adres e-mail: <a class="text-sky-400 after:content-['_↗']" href="mailto:kontakt@ekowitryna.pl">kontakt&#64;ekowitryna.pl</a></p>
    <div class="buttons">
      <button pButton (click)="openMoreInfo()">Więcej informacji</button>
      <button pButton (click)="openBuyNow()">Kup teraz</button>
    </div>
  </div>
</div>
