<div class="flex flex-col md:flex-row gap-4 z-40 relative">
  <!-- Podmiot (Company) -->
  <div class="flex flex-col gap-2 m-2 items-start">
    <p class="font-medium dark:text-white">Podmiot:</p>
    <p-skeleton *ngIf="loadingCompanies$ | async" class="w-[200px]" height="3rem"></p-skeleton>
    <p-dropdown
    *ngIf="(loadingCompanies$ | async) == false"
  class="w-full md:w-fit"
  [options]="companies$ | async"
  [ngModel]="selectedCompany$ | async"
  (ngModelChange)="onChangeBdoCredentials($event)"
  [style]="{ width: '100%', 'min-width': '200px' }"
  [filter]="true"
  filterBy="company.name"
>
  <ng-template let-item pTemplate="selectedItem">
    <div class="flex align-items-center gap-2">
      <img
        src="https://ui-avatars.com/api/?size=128&background=random&name={{ item?.company?.name }}"
        style="width: 18px"
        [alt]="item?.company?.name"
      />
      <div>{{ item?.company?.name }}</div>
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class="flex align-items-center gap-2">
      <img
        src="https://ui-avatars.com/api/?size=128&background=random&name={{ item?.company?.name }}"
        style="width: 18px"
        [alt]="item?.company?.name"
      />
      <div>{{ item?.company?.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
  </div>

  <!-- Miejsce prowadzenia działalności (Company Unit) -->
  <div class="flex flex-col gap-2 m-2 items-start" *ngIf="companyUnitSelectable">
    <p class="font-medium dark:text-white">Miejsce prowadzenia działalności:</p>
    <p-skeleton *ngIf="loadingCompanyUnits$ | async" class="w-[200px]" height="3rem"></p-skeleton>
    <div *ngIf="companyUnits$ | async as companyUnits" class="flex flex-col gap-2">
      <p-dropdown
        class="w-full md:w-fit"
        [options]="companyUnits$ | async"
        [ngModel]="selectedCompanyUnit$ | async"
        (ngModelChange)="onChangeBdoEup($event)"
        [style]="{ width: '100%' }"
        [filter]="true"
        filterBy="name"
      >
      <ng-template let-company pTemplate="selectedItem">
        <app-company-unit-dropdown-item [companyUnit]="company"></app-company-unit-dropdown-item>
      </ng-template>
      <ng-template let-company pTemplate="item">
        <app-company-unit-dropdown-item [companyUnit]="company"></app-company-unit-dropdown-item>
      </ng-template>
    </p-dropdown>
      <small class="text-red-500" *ngIf="selectedEupError">{{ selectedEupError }}</small>
    </div>
    <div class="flex gap-2 w-full md:w-fit">
      <p-inputSwitch
        class="ml-2"
        [disabled]="(loadingCompanyUnits$ | async) || (loadingCompanies$ | async)"
        id="hideInactiveEups"
        [(ngModel)]="showHidden$"
        (onChange)="onChangeHideInactiveEups($event)"
      />
      <label for="hideInactiveEups" class="dark:text-white">Ukryj nieaktywne MPD</label>
    </div>
  </div>
  <!-- Role dropdown -->
  <div *ngIf="roleSelectable" class="flex flex-col gap-2 m-2 items-start">
    <p class="font-medium dark:text-white">Rola:</p>
    <p-dropdown
      class="w-full md:w-fit"
      [options]="filteredRoles"
      [ngModel]="selectedRole$ | async"
      (ngModelChange)="onChangeRole($event)"
      [style]="{ width: '100%', 'min-width': '200px' }"
      optionLabel="label"
    ></p-dropdown>
</div>
