import { formatDate } from "@angular/common";

export class SearchCard{
  cardType: CardType;
  wasteCode: string;
  wasteCodeDescription: string;
  isUsed: boolean;
  lastModifiedAt: Date;
  communeId: string;
  cardId: string;
  cardNumber: string;
  nip: string;
  identificationNumber: string;
  nameOrFirstNameAndLastName: string;
  receiveConfirmationTime: Date;
  receiveConfirmationTimeText: string;
  addressHtml: string;
  terytPk: string;
  buildingNumber: string;
  localNumber: string;
  postalCode: string;
  hasNoBuildingNumber: boolean;
  foreignCompany: boolean;
  countryId: number;
  countryName: string;
  locality: string;
  street: string;
  wasteMass: number;
  wasteCodeExtended: boolean;
  wasteCodeExtendedDescription: string;
  hazardousWasteReclassification: boolean;
  hazardousWasteReclassificationDescription: string;
  cardSubtype?: CardSubType

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static from(card: any, cardType: CardType, role: string, cardSubtype?: CardSubType): SearchCard{
    const searchCard = new SearchCard();

    if(role === "SENDER"){
      searchCard.wasteCode = card.wasteCode;
      searchCard.wasteCodeDescription = card.wasteCodeDescription;
      searchCard.isUsed = card.isUsed;
      searchCard.lastModifiedAt = card.lastModifiedAt;
      searchCard.communeId = card.communeId;
      searchCard.cardId = (cardType === CardType.KPOK) ? card.kpokId : card.kpoId ;
      searchCard.cardNumber = card.cardNumber;
      searchCard.nip = card.receiverNip;
      searchCard.identificationNumber = card.receiverIdentificationNumber;
      searchCard.nameOrFirstNameAndLastName = card.receiverCompanyName;
      searchCard.receiveConfirmationTime = card.receiveConfirmationTime;
      searchCard.receiveConfirmationTimeText = formatDate(card.receiveConfirmationTime, 'short', "pl-PL");
      searchCard.addressHtml = card.addressHtml;
      searchCard.terytPk = card.terytPk;
      searchCard.buildingNumber = card.buildingNumber;
      searchCard.localNumber = card.localNumber;
      searchCard.postalCode = card.postalCode;
      searchCard.hasNoBuildingNumber = card.hasNoBuildingNumber;
      searchCard.foreignCompany = card.foreignCompany;
      searchCard.countryId = card.countryId;
      searchCard.countryName = card.countryName;
      searchCard.locality = card.locality;
      searchCard.street = card.street;
      searchCard.wasteMass = parseFloat(card.wasteMass);
      searchCard.cardType = cardType;
      searchCard.cardSubtype = cardSubtype;
    }

    if(role === Role.RECEIVER){
      searchCard.cardType = cardType;
      searchCard.wasteCode = card.wasteCode;
      searchCard.wasteCodeDescription = card.wasteCodeDescription;
      searchCard.isUsed = card.isUsed;
      searchCard.lastModifiedAt = card.lastModifiedAt;
      searchCard.communeId = card.communeId;
      searchCard.cardId = (cardType === CardType.KPOK) ? card.kpokId : card.kpoId ;
      searchCard.cardNumber = card.cardNumber;
      searchCard.nip = card.nip;
      searchCard.identificationNumber = card.identificationNumber;
      searchCard.nameOrFirstNameAndLastName = card.nameOrFirstNameAndLastName;
      searchCard.receiveConfirmationTime = card.receiveConfirmationTime;
      searchCard.receiveConfirmationTimeText = formatDate(card.receiveConfirmationTime, 'short', "pl-PL");
      searchCard.addressHtml = card.addressHtml;
      searchCard.terytPk = card.terytPk;
      searchCard.buildingNumber = card.buildingNumber;
      searchCard.localNumber = card.localNumber;
      searchCard.postalCode = card.postalCode;
      searchCard.hasNoBuildingNumber = card.hasNoBuildingNumber;
      searchCard.foreignCompany = card.foreignCompany;
      searchCard.countryId = card.countryId;
      searchCard.countryName = card.countryName;
      searchCard.locality = card.locality;
      searchCard.street = card.street;
      searchCard.wasteMass = parseFloat(card.wasteMass);
      searchCard.cardSubtype = cardSubtype;
    }

    return searchCard;
  }
}

export enum CardType {
  KPO = "KPO",
  KPOK = "KPOK"
}

export enum CardSubType {
  RECEIVE = "RECEIVE",
  TRANSFER = "TRANSFER"
}

export enum Role {
  SENDER = "SENDER",
  RECEIVER = "RECEIVER",
  CARRIER = "CARRIER"
}
