import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',

})
export class HeaderTitleComponent {

  @Input()
  public title: string;

  @Input()
  public subtitle: string;

  @ContentChild('actionButtonsTemplate')
  actionButtonsTemplate: TemplateRef<unknown>;
}
