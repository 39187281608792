/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BdoService } from '../bdoservice/bdoservice';
import { environment } from 'src/environments/environment';
import { AddCustomPlanRequest } from './models/add-custom-plan-request.model';

const BASE_URL = environment.apiUrl;


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  addCustomPlan(request: AddCustomPlanRequest): Promise<any> {
    return this.http.post(BASE_URL + 'accounts/admin/plans/add', request)
    .toPromise()
    .catch(AdminService.handleError);
  }

  static handleError(handleError: string): Promise<any> {
    console.log(handleError)
    throw new Error(BdoService.getErrorText(handleError));
  }
}
