import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Role } from 'src/app/services/bdoservice/wasterecord/models/search-card.model';
import { BdoServicePage } from 'src/app/services/bdoservice/common/model/bdo-service-page.model';
import { Observable, map, take, tap } from 'rxjs';
import { CompanyUnit } from 'src/app/services/bdoservice/companies/models/company-unit.model';
import { Company } from 'src/app/services/bdoservice/companies/models/company.model';
import { loadCompanies, changeSelectedCompany, loadCompanyUnits, changeSelectedCompanyUnit, changeShowHidden, changeSelectedRole } from './company-context.actions';
import { selectCompanies, selectCompanyUnits, selectLoadingCompanies, selectLoadingCompanyUnits, selectSelectedCompany, selectSelectedCompanyUnit, selectSelectedRole, selectShowHidden } from './company-context.selectors';
import { Store, select } from '@ngrx/store';
import { BdoCompanyItem } from 'src/app/services/bdoservice/companies/models/bdo-company-item';

@Component({
  selector: 'app-company-context-select',
  templateUrl: './company-context-select.component.html',

})
export class CompanyContextSelectComponent implements OnInit, OnChanges {

  @Input()
  public roleSelectable = true;

  @Input()
  public companyUnitSelectable = true;

  @Input()
  public disabled = false;

  @Input()
  public availableRoles: Role[] = [Role.SENDER, Role.CARRIER, Role.RECEIVER]

  roles = [
    new RoleDropdownItem(Role.SENDER, 'Przekazujący'),
    new RoleDropdownItem(Role.CARRIER, 'Transportujący'),
    new RoleDropdownItem(Role.RECEIVER, 'Przejmujący')
  ];


  filteredRoles = this.roles;

  companies$: Observable<BdoCompanyItem[]>;
  companyUnits$: Observable<CompanyUnit[]>;
  selectedCompany$: Observable<BdoCompanyItem>;
  selectedCompanyUnit$: Observable<CompanyUnit>;
  showHidden$: Observable<boolean>;
  loadingCompanies$: Observable<boolean>;
  loadingCompanyUnits$: Observable<boolean>;
  selectedBdoCredentials: Company | null = null;
  selectedEupError: string | null = null;
  selectedRole$: Observable<RoleDropdownItem | null>;

  constructor(private store: Store) {
    this.filteredRoles = this.availableRoles.map(role => this.roles.find(r => r.value === role));
    this.store.dispatch(changeSelectedRole({ role: this.filteredRoles[0] }));

    this.companies$ = this.store.pipe(select(selectCompanies));
    this.companyUnits$ = this.store.pipe(select(selectCompanyUnits)).pipe(map(page => page?.content));
    this.selectedCompany$ = this.store.pipe(select(selectSelectedCompany));
    this.selectedCompanyUnit$ = this.store.pipe(select(selectSelectedCompanyUnit));
    this.showHidden$ = this.store.pipe(select(selectShowHidden));
    this.loadingCompanies$ = this.store.pipe(select(selectLoadingCompanies));
    this.loadingCompanyUnits$ = this.store.pipe(select(selectLoadingCompanyUnits));
    this.selectedRole$ = this.store.pipe(select(selectSelectedRole));
  }

  ngOnInit() {
    this.store.dispatch(loadCompanies());
  }

  onChangeBdoCredentials(item: BdoCompanyItem) {
    // this.selectedBdoCredentials = company;
    this.store.dispatch(changeSelectedCompany({ item }));
    // this.store.dispatch(loadCompanyUnits({ companyId: company.id, page: 0, size: 10, showHidden: false }));
  }

  onChangeBdoEup(companyUnit: CompanyUnit) {
    if (companyUnit) {
      this.selectedEupError = null;
      this.store.dispatch(changeSelectedCompanyUnit({ companyUnit }));
    } else {
      this.selectedEupError = 'Error: Please select a valid unit';
    }
  }

  onChangeHideInactiveEups(event: any) {
    const showHidden: boolean = event.checked;
    this.store.dispatch(changeShowHidden({ showHidden }));
  }

  onChangeRole(role: RoleDropdownItem) {
    this.store.dispatch(changeSelectedRole({ role }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.availableRoles) {
      this.filteredRoles = this.availableRoles.map(role => this.roles.find(r => r.value === role));
      //if selected role is not in available roles, select first available role else keep selected role
      this.selectedRole$.pipe(take(1)).subscribe(selectedRole => {
        if (!this.filteredRoles.find(r => r.value === selectedRole.value)) {
          this.store.dispatch(changeSelectedRole({ role: this.filteredRoles[0] }));
        }
      });
    }
  }
}

export class RoleDropdownItem{
  constructor(
    readonly value: Role,
    readonly label: string
  ){}
}
