import { AbstractControl, ValidatorFn } from "@angular/forms";

export class KeycloakValidators {

  static passwordValidator(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      const password = control.value;

      if (!/[A-Z]/.test(password)) {
        return { uppercase: true };
      }

      if (!/[\W_]/.test(password)) {
        return { specialCharacter: true };
      }

      return null;
    };
  }
}
