<div>
  <h3 class="text-xl font-semibold">Lista pakietów</h3>
  <div *ngIf="packages?.length === 0">
    <h3 class="font-semibold text-xl text-red-500">Brak pakietów!</h3>
    <p>Zapraszamy do zapoznania się z naszą ofertą w zakładce <a routerLink="/dashboard/store">Sklep</a>.</p>
  </div>
  <div *ngIf="packages?.length > 0">
    <p-table [value]="packages"
    styleClass="p-datatable-striped p-datatable-gridlines"
    responsiveLayout="stack"
    [breakpoint]="'960px'">
      <ng-template pTemplate="header">
          <tr>
              <th scope="col">Nazwa</th>
              <th scope="col">Liczba operacji</th>
              <th scope="col">Data zakupu</th>
              <th scope="col">Status</th>
              <th scope="col">Data zakończenia</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-package>
          <tr>
              <td>
                <span class="p-column-title font-semibold">Nazwa:</span>
                {{package.name}}</td>
              <td>
                <span class="p-column-title font-semibold">Liczba operacji:</span>
                {{ getOperationCountText(package) }}</td>
              <td>
                <span class="p-column-title font-semibold">Data zakupu:</span>
                {{package.createDate | date: 'dd.MM.yyyy HH:mm'  }}</td>
              <td>
                <span class="p-column-title font-semibold">Status:</span>
                {{ getStatusText(package)}}
              </td>
              <td>
                <span class="p-column-title font-semibold">Data zakończenia:</span>
                {{package.endDate | date: 'dd.MM.yyyy HH:mm' }}</td>
          </tr>
      </ng-template>
  </p-table>
  </div>
</div>
