import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-secret-value-field',
  templateUrl: './secret-value-field.component.html',

})
export class SecretValueFieldComponent implements OnInit {

  @Input() value: string;

  hiddenText = '';
  visibleText = '';

  showSecret = false;

  ngOnInit(): void {
    this.visibleText = this.value.substring(0, 4);
    //generate * minus visibleText length
    for (let i = 0; i < this.value.length - 4; i++) {
      this.hiddenText += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    }
  }

  toggleVisibility() {
    this.showSecret = !this.showSecret;

    if (this.showSecret) {
      this.visibleText = this.value;
      this.hiddenText = '';
    } else {
      this.visibleText = this.value.substring(0, 4);
      //generate * minus visibleText length
      for (let i = 0; i < this.value.length - 4; i++) {
        //add random letter
        this.hiddenText += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
      }
    }
  }

  getIcon(): string {
    switch (this.showSecret) {
      case true:
        return 'pi pi-eye-slash';
      case false:
        return 'pi pi-eye';
    }
  }

}
