import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { BdoCredentials } from 'src/app/services/bdoservice/companies/models/bdo-credentials.model';
import { UsersService, RemoveBdoCredentialsRequest } from 'src/app/services/bdoservice/users/users.service';
import { AddCredentialsComponent, CredentialOperationType, CredentialsDialogData } from '../add-credentials/add-credentials.component';
import { Observable, map, shareReplay } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AccountService } from 'src/app/services/account.service';
import { CredentialsListComponentStore } from './credentials-list.store';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from 'src/app/services/bdoservice/companies/company.service';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials-list.component.html',

  providers: [ConfirmationService, CredentialsListComponentStore]
})
export class CredentialsListComponent {
  @Input() companyId: string;

  credentials$ = this.listStore.page$.pipe(map(page => page?.content));
  loading$ = this.listStore.loading$;
  page$ = this.listStore.page$;
  lastLazyLoadEvent: any;

  searchText = '';
  searchMode = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay(1)
    );

  removeLoading: Map<string, boolean> = new Map<string, boolean>();

  private createDialogRef: DynamicDialogRef | undefined;

  constructor(
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
    private companyService: CompanyService,
    private listStore: CredentialsListComponentStore,
    private route: ActivatedRoute,
    ) { }

  search(){
    this.searchMode = true;
    this.listStore.getPage(
      { page: 0, pageSize: 10, query: this.searchText, params: { companyId: this.route.snapshot.params['id'] } }
    );

  }

  clearSearch(){
    this.searchMode = false;
    this.searchText = '';
    this.listStore.getPage(
      { page: 0, pageSize: 10, query: '', sortBy: this.lastLazyLoadEvent.sortField ? `${this.lastLazyLoadEvent.sortField},${this.lastLazyLoadEvent.sortOrder === 1 ? 'asc' : 'desc'}` : '', params: { companyId: this.companyId } }
    );  
  }

  onLazyLoad(event: any){
    this.lastLazyLoadEvent = event;
    const sortBy = event.sortField ? `${event.sortField},${event.sortOrder === 1 ? 'asc' : 'desc'}` : '';
    this.listStore.getPage(
      { page: event.first / event.rows, pageSize: event.rows, query: this.searchText, sortBy: sortBy , params: { companyId: this.companyId } }
    );
  }

  showCreateDialog() {
    this.createDialogRef = this.dialogService.open(AddCredentialsComponent, {
      header: 'Dodaj klucz API',
      width: 'fit-content',
      data: new CredentialsDialogData(
        "",
        "",
        "",
        "",
        CredentialOperationType.CREATE_CREDENTIAL,
        () => {
          console.log("TEST ZAPIS");
          this.listStore.refresh();
        },
      )
    });

    this.createDialogRef.onClose.subscribe(result => {
      console.log(result);
      this.accountService.loadProfile();
    });
  }

  showEditDialog(credentials: BdoCredentials) {

    this.createDialogRef = this.dialogService.open(AddCredentialsComponent, {
      header: 'Edytuj klucz API',
      width: 'fit-content',
      data: new CredentialsDialogData(
        credentials.id,
        credentials.name,
        credentials.clientId,
        credentials.clientSecret,
        CredentialOperationType.EDIT_CREDENTIAL,
        () => {
          this.listStore.refresh();
        }

      )
    });

    this.createDialogRef.onClose.subscribe(() => {
      this.accountService.loadProfile();
    });
  }

  removeCredentials(event: Event, credentials: BdoCredentials) {
    const message = `Czy na pewno chcesz usunąć podmiot ${credentials.name}?`;

    this.confirmationService.confirm({
      target: event.target,
      icon: 'pi pi-exclamation-triangle',
      message: message,
      accept: () => {
        this.removeLoading.set(credentials.id, true);
        this.companyService.deleteCompanyCredentials(credentials.id).then(() => {
          this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Podmiot został usunięty.' });
          this.accountService.loadProfile();
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Błąd', detail: err.message || 'Wystąpił nieoczekiwany błąd.' });
        }).finally(() => {
          this.removeLoading.set(credentials.id, false);
        });
      }
    });
  }

  getRemoveLoading(credentials: BdoCredentials): boolean {
    return this.removeLoading.has(credentials?.id) ? this.removeLoading.get(credentials.id) : false;
  }
}
