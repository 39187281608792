import { createAction, props } from '@ngrx/store';
import { BdoServicePage } from 'src/app/services/bdoservice/common/model/bdo-service-page.model';
import { CompanyUnit } from 'src/app/services/bdoservice/companies/models/company-unit.model';
import { BdoCompanyItem } from 'src/app/services/bdoservice/companies/models/bdo-company-item';
import { RoleDropdownItem } from './company-context-select.component';

// Load Companies with Pagination
export const loadCompanies = createAction(
  '[Company Context] Load Companies'
);

export const loadCompaniesSuccess = createAction(
  '[Company Context] Load Companies Success',
  props<{ items: BdoCompanyItem[] }>()
);

export const loadCompaniesFailure = createAction(
  '[Company Context] Load Companies Failure',
  props<{ error: string }>()
);

export const restoreCompany = createAction(
  '[Company Context] Restore Company',
  props<{ companyId: string }>()
);


// Actions to change and select a company
export const changeSelectedCompany = createAction(
  '[Company Context] Change Selected Company',
  props<{ item: BdoCompanyItem }>()
);

// Actions to load company units
export const loadCompanyUnits = createAction(
  '[Company Context] Load Company Units',
  props<{ companyId: string; page: number; size: number; showHidden: boolean }>()
);

export const loadCompanyUnitsSuccess = createAction(
  '[Company Context] Load Company Units Success',
  props<{ page: BdoServicePage<CompanyUnit> }>()
);

export const loadCompanyUnitsFailure = createAction(
  '[Company Context] Load Company Units Failure',
  props<{ error: string }>()
);

// Actions to change and select a company unit
export const changeSelectedCompanyUnit = createAction(
  '[Company Context] Change Selected Company Unit',
  props<{ companyUnit: CompanyUnit }>()
);

// Action to change the visibility of hidden units
export const changeShowHidden = createAction(
  '[Company Context] Change Show Hidden',
  props<{ showHidden: boolean }>()
);

// Action to change the selected role
export const changeSelectedRole = createAction(
  '[Company Context] Change Selected Role',
  props<{ role: RoleDropdownItem }>()
);
