import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { JoinToStringPipe } from './pipes/join-to-string/join-to-string.pipe';
import { MaxTextLengthPipe } from './pipes/max-text-length/max-text-length.pipe';

import { CommonModule as __angular_common__ } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { CompanyContextSelectComponent } from './ui/company-context-select/company-context-select.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplashscreenComponent } from './ui/splashscreen/splashscreen.component';
import { HeaderTitleComponent } from './ui/header-title/header-title.component';
import { MenuComponent } from './ui/menu/menu.component';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { StatusPillComponent } from './ui/status-pill/status-pill.component';
import { SecretValueFieldComponent } from './ui/secret-value-field/secret-value-field.component';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { CompanyContextEffects } from './ui/company-context-select/company-context.effects';
import { ChipModule } from 'primeng/chip';
import { CompanyUnitDropdownItemComponent } from './ui/company-context-select/components/company-unit-dropdown-item/company-unit-dropdown-item.component';
@NgModule({
  declarations: [
    MaxTextLengthPipe,
    JoinToStringPipe,
    CompanyContextSelectComponent,
    SplashscreenComponent,
    HeaderTitleComponent,
    MenuComponent,
    StatusPillComponent,
    SecretValueFieldComponent,
    CompanyUnitDropdownItemComponent,
  ],
  imports: [
    AvatarModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    ProgressSpinnerModule,
    InputSwitchModule,
    RouterModule,
    TooltipModule,
    ButtonModule,
    SkeletonModule,
    ChipModule,
    EffectsModule.forFeature([CompanyContextEffects]),
    __angular_common__
  ],
  exports: [
    MaxTextLengthPipe,
    JoinToStringPipe,
    CompanyContextSelectComponent,
    SplashscreenComponent,
    HeaderTitleComponent,
    MenuComponent,
    StatusPillComponent,
    SecretValueFieldComponent,
    CompanyUnitDropdownItemComponent,
  ],
})
export class CommonModule { }
