// theme.service.ts
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private darkMode = new BehaviorSubject<boolean>(false);
  darkMode$ = this.darkMode.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.darkMode.next(localStorage.getItem('darkMode') === 'true');
    this.setClass();
    this.setThemeCss();
  }


  get isDarkMode(): boolean {
    return this.darkMode.value;
  }

  toggleDarkMode() {
    this.darkMode.next(!this.darkMode.value);
    localStorage.setItem('darkMode', this.darkMode.value.toString());
    this.setClass();
    this.setThemeCss();
  }

  private setThemeCss() {
    const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = this.isDarkMode
        ? 'assets/themes/material/dark/theme.css'
        : 'assets/themes/material/light/theme.css';
    }
  }

  private setClass(){
    if (this.darkMode.value) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }
}
