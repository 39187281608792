import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { CompanyService } from 'src/app/services/bdoservice/companies/company.service';

import {
  loadCompanies,
  loadCompaniesSuccess,
  loadCompaniesFailure,
  loadCompanyUnitsFailure,
  changeSelectedCompany,
  changeSelectedCompanyUnit,
  changeSelectedRole,
  loadCompanyUnitsSuccess,
} from './company-context.actions';

@Injectable()
export class CompanyContextEffects {
  // Efekt ładowania firm z serwera
  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompanies),
      exhaustMap(() =>
        from(this.companyService.getBdoCompanies()).pipe(
          map(items => loadCompaniesSuccess({ items })),
          catchError(error => of(loadCompaniesFailure({ error: error.message })))
        ))
    )
  );

  // Efekt wyboru początkowej firmy po załadowaniu firm
  selectInitialCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompaniesSuccess),
      exhaustMap(({ items }) => {
        const savedState = JSON.parse(localStorage.getItem('companyContext')).companyContext;
        const savedCompanyId = savedState?.selectedCompany?.company?.id;
        const foundCompanyOrDefault = items.find(company => company.company.id === savedCompanyId) || items[0];

        if (foundCompanyOrDefault) {
          return of(changeSelectedCompany({ item: foundCompanyOrDefault }));
        } else {
          return of(loadCompanyUnitsFailure({ error: 'No matching companies found to select.' }));
        }
      })
    )
  );

  // Efekt ładowania jednostek firmy po wyborze firmy
  loadCompanyUnitsWhenCompanySelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSelectedCompany),
      exhaustMap(({ item }) => {
        return from(this.companyService.getCompanyUnits(item.company.id, false, 0, 10000, '')).pipe(
          map(page => {
            const savedState = JSON.parse(localStorage.getItem('companyContext')).companyContext;
            const savedCompanyUnitId = savedState?.selectedCompanyUnit?.id;
            const foundCompanyUnitOrDefault = page.content.find(unit => unit.id === savedCompanyUnitId) || page.content[0];

            if (foundCompanyUnitOrDefault) {
              return changeSelectedCompanyUnit({ companyUnit: foundCompanyUnitOrDefault });
            } else {
              return loadCompanyUnitsFailure({ error: 'No matching company units found to select.' });
            }
          }),
          catchError(error => of(loadCompanyUnitsFailure({ error: error.message })))
        );
      })
    )
  );

  loadCompanyUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSelectedCompany),
      mergeMap(({ item }) =>
        from(this.companyService.getCompanyUnits(item.company.id, false, 0, 10000, '')).pipe(
          map(page => loadCompanyUnitsSuccess({ page })),
          catchError(error => of(loadCompanyUnitsFailure({ error: error.message })))
        )
      )
    )
  );

  // Efekt ustawiania roli z localStorage po załadowaniu jedno

  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
  ) {}
}
