import { Component, Input } from '@angular/core';
import { PackageInfo } from './package-info.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-tile',
  templateUrl: './sidebar-tile.component.html',

})
export class SidebarTileComponent {

  constructor(
   private readonly router: Router,
  ){}

  @Input()
  set packageInfo(value: PackageInfo | null) {
    this.loading = false;
    this._packageInfo = value;

    if(this._packageInfo === null) {
      this.status = 'error';
    } else {
      this.status = 'info';
    }
  }

  _packageInfo: PackageInfo | null = null;

  @Input()
  public mode: 'desktop' | 'mobile' = 'desktop';

  status: 'warning' | 'error' | 'info' = 'error';

  public loading = true;

  calculateProgressPercentage(): number {
    if(this._packageInfo?.state === null || this._packageInfo?.max === null) {
      return 100;
    }

    return (this._packageInfo?.state / this._packageInfo?.max) * 100;
  }

  getProgress(): number {
    return this._packageInfo?.state;
  }

  getMax(): number {
    return this._packageInfo?.max;
  }

  getIconClass(): string {
    switch(this.status){
      case 'error':
        return 'pi pi-exclamation-triangle';
      case 'warning':
        return 'pi pi-exclamation-circle';
      case 'info':
        return 'pi pi-check-circle';
    }
  }
}
