export const environment = {
  production: false,
  apiUrl: "https://server.dev2.bdomobile.com/",
  keycloakUrl: "https://keycloak-dev.mobiletract.com",
  realm: "bdo-dev2",
  tpayScript: "https://secure.tpay.com/groups-696041.js",
  clientId: "bdo-web-panel",
  googleTagManagerId: 'GTM-T43SC6BD',
  googleTagManagerPreview: 'env-3',
  googleTagManagerAuth: 'nwRCCNlB1-SPt0eiDeFIOw'

};
