import { Component } from '@angular/core';

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',

})
export class SplashscreenComponent {

}
