import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
})
export class StatusPillComponent {
  @Input() status: 'success' | 'warning' | 'danger' | 'progress';
  @Input() text: string;
}
