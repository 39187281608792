/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EditEmployeeRequest, EmployeeRequest } from 'src/app/dashboard/users/users/users.component';
import { environment } from 'src/environments/environment';
import { BdoService } from '../bdoservice';
import { AddBdoCredentialsRequest } from '../companies/models/add-bdo-credentials-request.model';
import { AddBdoCredentialsResponse } from '../companies/models/add-bdo-credentials-response.model';
import { ChangeEmployeePasswordRequest } from './models/change-employee-password-request.model';
import { ChangePasswordRequest } from './models/change-password-request.model';
import { CompanyProfile } from './models/company-profile.model';
import { CompanySignupRequest } from './models/company-signup-request.model';
import { EditBdoCredentialsRequest } from '../companies/models/edit-bdo-credentials-request.model';
import { EditCompanyProfile } from './models/edit-company-request.model';
import { Employee } from './models/employee.model';
import { EupItem } from './models/eup-item.model';
import { GetEupListRequest } from './models/get-eup-list-request.model';
import { InitCompanyAccountRequest } from './models/init-company-account.model';
import { Profile } from './models/profile.model';
import { RemoveBdoCredentialsRequest } from './models/remove-bdo-credentials-request.model';
import { ResetChangePasswordRequest } from './models/reset-change-password-request.model';
import { ResetPasswordRequest } from './models/reset-password-request.model';
import { SaveCompanyInvoiceRequest } from './models/save-company-invoice-request.model';
import { EditEmployeePasswordRequest } from './models/edit-employee-password-request.model';

export { AddBdoCredentialsResponse } from '../companies/models/add-bdo-credentials-response.model';
export { ChangePasswordRequest } from './models/change-password-request.model';
export { CompanyProfile } from './models/company-profile.model';
export { CompanySignupRequest } from './models/company-signup-request.model';
export { EditBdoCredentialsRequest } from '../companies/models/edit-bdo-credentials-request.model';
export { EditCompanyProfile } from './models/edit-company-request.model';
export { Employee } from './models/employee.model';
export { Profile } from './models/profile.model';
export { RemoveBdoCredentialsRequest } from './models/remove-bdo-credentials-request.model';
export { ResetChangePasswordRequest } from './models/reset-change-password-request.model';
export { ResetPasswordRequest } from './models/reset-password-request.model';
export { SaveCompanyInvoiceRequest } from './models/save-company-invoice-request.model';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  setAccountType(type: AccountType){
    localStorage.setItem("account_type", type)
  }

  getAccountType(): AccountType{
    return AccountType[localStorage.getItem("account_type") as keyof typeof AccountType]
  }

  removeEmployee(username: string) {
    return this.http.delete<any>(BASE_URL + 'accounts/company/employee', {params: new HttpParams().set("employeeUsername", username)})
    .toPromise()
    .catch(UsersService.handleError);
  }

  getEupList(request: GetEupListRequest): Promise<EupItem[]> {
    return this.http.get<any>(BASE_URL + 'accounts/company/bdo/eup/all', {params: {
      credentialsId: request.credentialsId,
    }})
    .toPromise()
    .catch(UsersService.handleError);
  }

  getEmployees(): Promise<Employee[]> {
    return this.http.get<Employee[]>(BASE_URL + 'accounts/company/employee')
    .toPromise()
    .catch(UsersService.handleError);
  }

  saveEmployee(employee: EmployeeRequest): Promise<any>{
    return this.http.post<void>(BASE_URL + 'accounts/company/employee/signup', employee)
    .toPromise()
    .catch(UsersService.handleError);
  }

  editEmployee(employee: EditEmployeeRequest): Promise<any> {
    return this.http.post<void>(BASE_URL + 'accounts/company/employee/edit', employee)
    .toPromise()
    .catch(UsersService.handleError);
  }

  getProfile(accountType: AccountType = AccountType.COMPANY): Promise<Profile>{
    //TODO TEMPORARY SET ACCOUNT TYPE TO COMPANY
    this.setAccountType(accountType);

    if(this.getAccountType() === AccountType.COMPANY){
      return this.http.get<CompanyProfile>(BASE_URL + 'accounts/company/profile')
      .toPromise()
      .catch(UsersService.handleError);
    } else {
      return this.http.get<CompanyProfile>(BASE_URL + 'accounts/employee/profile')
      .toPromise()
      .catch(UsersService.handleError);
    }

  }

  signup(request: CompanySignupRequest) : Promise<any> {
    return this.http.post(BASE_URL + 'accounts/company/signup', request)
    .toPromise()
    .catch(UsersService.handleError);
  }

  editCompanyProfile(request: EditCompanyProfile): Promise<any>{
    return this.http.post(BASE_URL + 'accounts/company/profile/edit', request)
    .toPromise()
    .catch(UsersService.handleError);
  }

  saveCompanyInvoice(request: SaveCompanyInvoiceRequest){
    return this.http.post(BASE_URL + 'accounts/company/profile/edit', request)
    .toPromise()
    .catch(UsersService.handleError);
  }

  changeCompanyPassword(request: ChangePasswordRequest){
    return this.http.post(BASE_URL + 'accounts/company/profile/edit', request)
    .toPromise()
    .catch(UsersService.handleError);
  }

  changeEmployeePassword(request: ChangeEmployeePasswordRequest){
    return this.http.post(BASE_URL + 'accounts/company/employee/change-password', request)
    .toPromise()
    .catch(UsersService.handleError);
  }

  editEmployeePassword(request: EditEmployeePasswordRequest){
    return this.http.post(BASE_URL + 'accounts/employee/edit-password', request)
    .toPromise()
    .catch(UsersService.handleError);
  }

  initAccount(request: InitCompanyAccountRequest){
    return this.http.post(BASE_URL + 'accounts/company/init', request)
    .toPromise()
    .catch(UsersService.handleError);
  }

  static handleError(handleError: unknown): Promise<any> {
    console.log(handleError)
    throw new Error(BdoService.getErrorText(handleError));
  }
}

export enum AccountType{
  EMPLOYEE = "EMPLOYEE",
  COMPANY = "COMPANY"
}
