export class EditBdoCredentialsRequest{
  credentialsId: string;
  name: string;
  clientId: string;
  clientSecret: string;

  constructor(credentialsId: string, name: string, clientId: string, clientSecret: string){
    this.credentialsId = credentialsId
    this.name = name;
    this.clientId = clientId;
    this.clientSecret = clientSecret
  }
}
