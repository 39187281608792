import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { KeycloakService } from 'keycloak-angular';
import { Component, Input } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StoreProduct } from 'src/app/services/bdoservice/store/models/store-product.model';
import { InvoiceFormDialogComponent, InvoiceFormDialogData } from '../invoice-form-dialog/invoice-form-dialog.component';
import { CompanyProfile } from 'src/app/services/bdoservice/users/users.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, map, shareReplay } from 'rxjs';
import { AdminService } from 'src/app/services/admin/admin.service';
import { AddCustomPlanRequest } from 'src/app/services/admin/models/add-custom-plan-request.model';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-shop-package-item',
  templateUrl: './shop-package-item.component.html',

  providers: [DialogService],
})
export class ShopPackageItemComponent {

  @Input() product: StoreProduct;

  @Input() companyProfile: CompanyProfile;

  @Input() disableBuy = false;

  @Input() canAdminAdd = false;

  buyDialogRef: DynamicDialogRef | undefined;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isHandset = false;

  addCustomPlanLoading = false;
  adminIcon = faScrewdriverWrench;

  constructor(
    private dialogService: DialogService,
    private breakpointObserver: BreakpointObserver,
    private keycloakService: KeycloakService,
    private messageService: MessageService,
    private adminService: AdminService,
    private accountService: AccountService
  ) {
    this.isHandset$.subscribe(isHandset => {
      this.isHandset = isHandset;
    });
  }

  onBuyClick() {
    this.buyDialogRef = this.dialogService.open(InvoiceFormDialogComponent, {
      header: 'Wybierz rodzaj faktury',
      width: this.isHandset ? '100vw' : '50vw',
      data: new InvoiceFormDialogData(this.companyProfile, this.product)
    });
  }

  isAdmin(): boolean {
    return this.keycloakService.getUserRoles()?.includes('ADMIN')
  }

  adminAddClick() {
    //check role is admin
    if (this.keycloakService.getUserRoles()?.includes('ADMIN')) {
      this.addCustomPlanLoading = true;

      this.adminService.addCustomPlan(
        AddCustomPlanRequest.fromStoreProduct(this.product)
      ).then(() => {
        this.messageService.add({ severity: 'success', summary: 'Sukces', detail: 'Pomyślnie dodano pakiet' });
        this.accountService.refreshPackage();
      }).catch((error) => {
        this.messageService.add({ severity: 'error', summary: 'Błąd', detail: error.message });
      }
      ).finally(() => {
        this.addCustomPlanLoading = false;
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Błąd', detail: 'Nie masz uprawnień do tej operacji' });
    }
  }

  getPrice(): string {
    if (this.product?.sale_price) {
      return this.product?.sale_price;
    } else {
      return this.product?.price;
    }
  }
}
