import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AccountValidators } from 'src/app/services/bdoservice/constants';
import { nipValidator } from 'src/app/common/validators/nipNumber/nip-number.validator';
import { CompanyInvoice } from 'src/app/services/bdoservice/users/models/company-invoice.model';
import { PersonInvoice } from 'src/app/services/bdoservice/users/models/person-invoice.model';
import { CompanyProfile, UsersService, SaveCompanyInvoiceRequest } from 'src/app/services/bdoservice/users/users.service';

@Component({
  selector: 'app-edit-company-invoice-card',
  templateUrl: './edit-company-invoice-card.component.html',

})
export class EditCompanyInvoiceCardComponent implements OnInit {

  InvoiceType = InvoiceType
  formTypes = [
    { name: "Osoba prywatna", value: InvoiceType.PERSON },
    { name: "Firma", value: InvoiceType.COMPANY },
  ];

  @Input()
  companyProfile: CompanyProfile;

  @Output()
  dataSaved = new EventEmitter<SavedInvoiceItem>();

  selectedType = InvoiceType.PERSON;
  form: FormGroup;
  saveCompanyInvoiceError = '';
  saving = false;

  constructor(
    private usersService: UsersService) { }

  saveCompanyInvoice() {
    this.saving = true;

    const request = new SaveCompanyInvoiceRequest();

    if (this.selectedType === InvoiceType.COMPANY) {
      const companyInvoice = new CompanyInvoice();
      companyInvoice.firstName = this.form.controls.firstName.value ?? this.companyProfile.firstName;
      companyInvoice.lastName = this.form.controls.lastName.value ?? this.companyProfile.lastName;
      companyInvoice.companyName = this.form.controls.companyName.value;
      companyInvoice.address = this.form.controls.address.value;
      companyInvoice.city = this.form.controls.city.value;
      companyInvoice.postCode = this.form.controls.postCode.value;
      companyInvoice.nipNumber = this.form.controls.nipNumber.value;
      request.companyInvoice = companyInvoice;
    } else if (this.selectedType === InvoiceType.PERSON) {
      const personInvoice = new PersonInvoice();
      personInvoice.firstName = this.form.controls.firstName.value;
      personInvoice.lastName = this.form.controls.lastName.value;
      personInvoice.address = this.form.controls.address.value;
      personInvoice.city = this.form.controls.city.value;
      personInvoice.postCode = this.form.controls.postCode.value;
      request.personInvoice = personInvoice;
    } else {
      console.log("Invalid parameter for purchase invoice");
    }

    this.usersService.saveCompanyInvoice(request).then(() => {
      const savedInvoiceItem = new SavedInvoiceItem(this.selectedType, request.companyInvoice ?? request.personInvoice);
      this.dataSaved.emit(savedInvoiceItem);
    }).catch(err => {
      this.saveCompanyInvoiceError = err.message;
    }).finally(() => {
      this.saving = false;
    });
  }

  ngOnInit(): void {
    if (this.companyProfile.companyInvoice) {
      this.selectedType = InvoiceType.COMPANY;
    } else if (this.companyProfile.personInvoice) {
      this.selectedType = InvoiceType.PERSON;
    }

    const personInvoice = this.companyProfile.personInvoice;
    const companyInvoice = this.companyProfile.companyInvoice;

    this.form = new FormGroup({
      type: new FormControl(this.selectedType, Validators.required),
      firstName: new FormControl(personInvoice?.firstName ?? companyInvoice?.firstName ?? this.companyProfile.firstName ?? '', Validators.required),
      lastName: new FormControl(personInvoice?.lastName ?? companyInvoice?.lastName ?? this.companyProfile.lastName ?? '', Validators.required),
      address: new FormControl(personInvoice?.address ?? companyInvoice?.address ?? '', Validators.required),
      postCode: new FormControl(personInvoice?.postCode ?? companyInvoice?.postCode ?? '', [Validators.required, Validators.pattern(AccountValidators.POST_CODE_PATTERN)]),
      city: new FormControl(personInvoice?.city ?? companyInvoice?.city ?? '', Validators.required),
      nipNumber: new FormControl(companyInvoice?.nipNumber ?? '', [Validators.required, nipValidator]),
      companyName: new FormControl(companyInvoice?.companyName ?? '', Validators.required)
    });

    this.updateFormValidation(this.selectedType);

    this.form.controls.type.valueChanges.subscribe(type => {
      this.selectedType = type;
      this.updateFormValidation(type);
    });
  }

  isRequired(field: string): boolean {
    return this.form.controls[field].hasValidator(Validators.required);
  }

  updateFormValidation(type: InvoiceType) {
    const companyNameCtrl = this.form.controls.companyName;
    const nipNumberCtrl = this.form.controls.nipNumber;

    if (type == InvoiceType.PERSON) {
      companyNameCtrl.clearValidators();
      nipNumberCtrl.clearValidators();
    } else if (type == InvoiceType.COMPANY) {
      companyNameCtrl.setValidators([Validators.required]);
      nipNumberCtrl.setValidators([Validators.required, nipValidator]);
    }

    companyNameCtrl.updateValueAndValidity();
    nipNumberCtrl.updateValueAndValidity();
  }

  getNipNumberError(): string {
    const field = this.form.get('nipNumber');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('nip')) {
      return 'Numer NIP musi być prawidłowy oraz bez znaków specjalnych.';
    }

    return '';
  }

  getPostCodeError(): string {
    const field = this.form.get('postCode');

    if (field.hasError('required')) {
      return 'Pole jest wymagane.';
    }

    if (field.hasError('pattern')) {
      return 'Kod pocztowy musi być w formacie XX-XXX.';
    }

    return '';
  }
}

export enum InvoiceType {
  PERSON = "PERSON",
  COMPANY = "COMPANY"
}

export class SavedInvoiceItem {
  constructor(
    readonly type: InvoiceType,
    readonly invoice: PersonInvoice | CompanyInvoice
  ) { }
}
