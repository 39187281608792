<div class="gap-2">
  <p>
    Korzystając z tego formularza, możesz wprowadzić dane podmiotu, którym chcesz zarządzać w aplikacji. Jeśli napotkasz trudności z integracją, skorzystaj z dostępnej instrukcji, obejrzyj film instruktażowy lub skontaktuj się z naszym działem wsparcia.
  </p>
  <app-create-company-instructions />
  <form [formGroup]="form" class="flex flex-col gap-2">
    <p class="flex flex-col gap-2">
      <label for="name">Nazwa firmy<span *ngIf="isRequired('name')" class="text-red-500">*</span></label>
      <input type="text" pInputText formControlName="name" [required]="isRequired('name')"/>
      <small class="text-red-500" *ngIf="!form.controls['name'].valid && form.controls['name'].touched">{{ getError('name') }}</small>
      <small class="text-gray-500 italic" *ngIf="isAuto()">Nazwa firmy zostanie pobrana automatycznie.</small>

    </p>
    <!-- nameFromBdo boolean value -->
    <p *ngIf="config.data.mode === 'create'" class="flex gap-2">
      <p-checkbox formControlName="nameFromBdo" binary="true" (onChange)="onChangeNameFromBdo($event)" ></p-checkbox>
      <label for="nameFromBdo">Pobierz dane firmy z rejestru ministerialnego BDO</label>
    </p>
    <p *ngIf="config.data.mode === 'create'" class="flex flex-col gap-2">
      <label for="nip">NIP<span *ngIf="isRequired('nip')" class="text-red-500">*</span></label>
      <input type="text" pInputText formControlName="nip" [required]="isRequired('nip')"/>
      <small class="text-red-500" *ngIf="!form.controls['nip'].valid && form.controls['nip'].touched">{{ getError('nip') }}</small>
      <small class="text-gray-500 italic" *ngIf="isAuto()">NIP zostanie pobrany automatycznie.</small>

    </p>
    <p *ngIf="config.data.mode === 'create'" class="flex flex-col gap-2">
      <label for="clientId">Client Id<span *ngIf="isRequired('entryType')" class="text-red-500">*</span></label>
      <input type="text" pInputText formControlName="clientId" [required]="isRequired('clientId')"/>
      <small class="text-red-500" *ngIf="!form.controls['clientId'].valid && form.controls['clientId'].touched">{{ getError('clientId') }}</small>
    </p>
    <p *ngIf="config.data.mode === 'create'" class="flex flex-col gap-2">
      <label for="clientSecret">Client Secret<span *ngIf="isRequired('entryType')" class="text-red-500">*</span></label>
      <input type="text" pInputText formControlName="clientSecret" [required]="isRequired('clientSecret')"/>
      <small class="text-red-500" *ngIf="!form.controls['clientSecret'].valid && form.controls['clientSecret'].touched">{{ getError('clientSecret') }}</small>
    </p>
    <!-- Chip group dodania aliasów -->
    <small class="text-red-500" *ngIf="formError">{{ formError }}</small>
  </form>
</div>
<div class="mt-4 flex gap-2">
  <p-button label="Wróć" [text]="true" (onClick)="onNoClick()"></p-button>
  <p-button label="Zapisz" (onClick)="saveForm()" [disabled]="!form.valid"></p-button>
</div>
