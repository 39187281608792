import { Injectable } from "@angular/core";
import { CompanyService } from "src/app/services/bdoservice/companies/company.service";
import { Company } from "src/app/services/bdoservice/companies/models/company.model";
import { FetchParams, GenericListComponentStore } from "src/app/common/store/generic-list-component-store";


@Injectable()
export class CompanyListStore extends GenericListComponentStore<Company, FetchParams> {

  constructor(
    private readonly companyService: CompanyService,
  ) {
    super((data) => this.companyService.getCompanies(data.page, data.pageSize, data.query, data.sortBy));
  }
}
